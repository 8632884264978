import React from 'react';

import * as Styled from './index.styles';
import { Props } from './index.types';

export const FlexItem = React.forwardRef(function FlexItem(
  {
    as = 'div',
    flexBasis = 'auto',
    flexGrow = 0,
    flexShrink = 1,
    backgroundColor = 'inherit',
    children,
    ...rest
  }: React.PropsWithChildren<Props>,
  forwardedRef: React.Ref<HTMLDivElement>,
) {
  return (
    <Styled.FlexItem
      as={as}
      flexBasis={flexBasis}
      flexGrow={flexGrow}
      flexShrink={flexShrink}
      backgroundColor={backgroundColor}
      ref={forwardedRef}
      {...rest}
    >
      {children}
    </Styled.FlexItem>
  );
});

export default FlexItem;
