import {
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { ApiErrorResponse, ApiResponse } from '@/types/api';
import { api } from '@/utils/api';

export interface CheckFileUploadRequest {
  fileId: number;
}

const checkFileUpload = ({ fileId }: CheckFileUploadRequest) => {
  return api.put<ApiResponse>('/v2/file/upload/check', {
    fileId,
  });
};

const useCheckFileUpload = <TError = AxiosError<ApiErrorResponse>>(
  options?: UseMutationOptions<
    AxiosResponse<ApiResponse>,
    TError,
    CheckFileUploadRequest,
    QueryKey
  >,
): UseMutationResult<
  AxiosResponse,
  TError,
  CheckFileUploadRequest,
  QueryKey
> => {
  return useMutation(checkFileUpload, {
    ...options,
  });
};

export default useCheckFileUpload;
