import { atom } from 'jotai';

import { FileUploadingItemType } from '@/hooks/queries/file/useFileUploadList';

/*파일 업로드 중일 경우 pdf 목록 페이지에서 파일 관련 정보를 보여주기 위한 전역 상태 */
export const UploadingFileInfoListAtom = atom<FileUploadingItemType[]>([]);

/*파일 업로드 모달에서 모달이 닫힌 후 바로 upload api를 호출할 수 있도록 Trigger하는 상태 */
export const isTriggerUploadingRequestAtom = atom<boolean>(false);

/*파일 수정시 사용자가 문의양식을 설정하지 않은 파일에 대해 id를 배열 형식으로 쌓는 상태 */
export const notSetCtaFormFileIdListAtom = atom<FileUploadingItemType[]>([]);

/*파일 업로드 완료시 뜨는 모달 관련 Open 상태 */
export const isShowUploadingModalAtom = atom<boolean>(false);

/*파일 새롭게 생성 후 time out 5초 후 모달이 닫히도록 구현하기 위한 리스트 */
export const createSuccessFileIdListAtom = atom<number[]>([]);
