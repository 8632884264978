import { FileUploadStatusType } from '@/hooks/queries/file/useFileUploadList';

import * as Styled from './index.styles';

interface Props {
  totalPage: number;
  uploadStatus: FileUploadStatusType;
}

const ProgressBar = ({ totalPage, uploadStatus }: Props) => {
  const getCurrenUploadingPercent = () => {
    if (uploadStatus.includes('Convert')) {
      const page = uploadStatus.replace('Convert:', '');
      return Number(page) / totalPage;
    }
    return 0;
  };

  if (uploadStatus === 'Start') {
    return <Styled.PendingProgressBar />;
  }

  if (uploadStatus === 'Error') {
    return <Styled.ErrorProgressBar />;
  }

  if (uploadStatus === 'Success') {
    return <Styled.SuccessProgressBar />;
  }

  return (
    <Styled.ProgressBarWrapper>
      <Styled.ProgressBar
        initial={{ width: 0 }}
        animate={{ width: `${getCurrenUploadingPercent() * 100}%` }}
        transition={{ duration: 0.25 }}
      />
    </Styled.ProgressBarWrapper>
  );
};

export default ProgressBar;
