import ChannelIO from './ChannelIO';
import GoogleAnalytics from './GoogleAnalytics';
import GoogleTagManager from './GoogleTagManager';
import Recatch from './Recatch';

const Scripts = () => {
  return (
    <>
      <GoogleAnalytics />
      <GoogleTagManager />
      <ChannelIO />
      <Recatch />
    </>
  );
};

export default Scripts;
