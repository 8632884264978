import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const ProgressBarTemplate = styled.div`
  width: 80%;
  height: 19px;

  border-radius: 16px;
`;

export const ErrorProgressBar = styled(ProgressBarTemplate)`
  background-color: ${({ theme }) => theme.colors.cerise_400};
`;

export const SuccessProgressBar = styled(ProgressBarTemplate)`
  background-color: ${({ theme }) => theme.colors.skyblue_400};
`;

export const PendingProgressBar = styled(ProgressBarTemplate)`
  background-color: ${({ theme }) => theme.colors.zinc_200};
`;

export const ProgressBarWrapper = styled.div`
  width: 80%;
  height: 19px;
  background-color: ${({ theme }) => theme.colors.zinc_50};

  border-radius: 16px;
`;

export const ProgressBar = styled(motion.div)`
  height: 19px;
  background-color: ${({ theme }) => theme.colors.skyblue_400};
  border-radius: 16px;
`;
