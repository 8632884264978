import HeadComponent from 'next/head';

const Head = () => {
  return (
    <HeadComponent>
      <title key="title">중요한 고객에게 자료를 보낼 땐, SalesClue</title>
      <meta
        key="keywords"
        name="keywords"
        content="CRM, sales, 세일즈, 영업, 고객관리"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta
        key="metaTitle"
        name="title"
        content="중요한 고객에게 자료를 보낼 땐, SalesClue"
      />
      <meta
        key="description"
        name="description"
        lang="ko"
        content="B2B 세일즈 성공률 높이는 법: '관심 있는' 고객부터 공략하기. 세일즈클루로 관심 있는 고객을 파악하고, 고객이 관심을 가지는 내용으로 세일즈를 이어가세요."
      />
      <meta key="author" name="author" content="Pairy, 페어리 주식회사" />
      <meta
        key="ogTitle"
        property="og:title"
        content="중요한 고객에게 자료를 보낼 땐, SalesClue"
      />
      <meta
        key="ogDescription"
        property="og:description"
        content="B2B 세일즈 성공률 높이는 법: '관심 있는' 고객부터 공략하기. 세일즈클루로 관심 있는 고객을 파악하고, 고객이 관심을 가지는 내용으로 세일즈를 이어가세요."
      />
      <meta
        key="ogImage"
        property="og:image"
        content="https://user-images.githubusercontent.com/41149744/201258908-08685876-f2c8-400a-9e88-7c2a57e7b3b1.png"
      />

      <meta name="robots" content="all" />
    </HeadComponent>
  );
};

export default Head;
