import React from 'react';

import * as Styled from './index.styles';
import Spinner from './Spinner';

const Loading = ({ children }: React.PropsWithChildren) => {
  return (
    <div>
      <Styled.Dimmed />
      {children ? children : <Spinner />}
    </div>
  );
};

export default Loading;
