import { theme } from '@pairy/styles';

import { IconNames } from '../Icon';

import * as Styled from './index.styles';

interface Props {
  icon: IconNames;
  size: number;
  color?: keyof typeof theme.colors;
  bottom: number;
  onClick?: () => void;
}

/** NOTE: 추후 사용성에 따라 fixed 등 커스터마이징 */
export const FixedAnimationIcon = ({
  icon,
  size,
  color,
  bottom,
  onClick,
}: Props) => {
  return (
    <Styled.Wrapper bottom={bottom} size={size} onClick={onClick}>
      <Styled.AnimationIcon icon={icon} size={size} color={color} />
    </Styled.Wrapper>
  );
};
