import { useEffect, useState } from 'react';

import { GetFileUploadListResponse } from '@/hooks/queries/file/useFileUploadList';

interface Props {
  fileData: GetFileUploadListResponse | undefined;
}

const useCtaFormPageDropdown = ({ fileData }: Props) => {
  const [totalPage, setTotalPage] = useState(1);

  const dropdownItems = Array.from(
    { length: totalPage as number },
    (_, index) => {
      const pageNumber = index + 1;

      return {
        label: `${pageNumber} 페이지`,
        value: `${pageNumber}`,
      };
    },
  ) as { label: string; value: string }[];

  useEffect(() => {
    const successElement = fileData?.find(
      (item) => item.uploadStatus === 'Success',
    );

    if (successElement) {
      setTotalPage(successElement.pages);
    }
  }, [fileData]);

  return {
    dropdownItems,
  };
};

export default useCtaFormPageDropdown;
