import { Icon, Stack, Text } from '@pairy/pds-react';

const UploadSuccess = () => {
  return (
    <Stack gap={8} width="fit-content">
      <Icon icon="ph:seal-check" color="skyblue_500" size={19} />
      <Text variant="lg-semibold" color="skyblue_500">
        업로드 완료
      </Text>
    </Stack>
  );
};

const UploadFail = () => {
  return (
    <Stack gap={8} width="fit-content">
      <Icon icon="ph:seal-warning" color="cerise_500" size={19} />
      <Text variant="lg-semibold" color="cerise_500">
        업로드 실패
      </Text>
    </Stack>
  );
};

const UploadPending = () => {
  return (
    <Text variant="lg-semibold" color="zinc_400">
      업로드 준비중
    </Text>
  );
};

export const UploadStatus = {
  success: UploadSuccess,
  fail: UploadFail,
  pending: UploadPending,
};
