import * as Dialog from '@radix-ui/react-dialog';

import Text from '../../../Text';
import { Modal } from '../index';

import * as Styled from './index.styles';

export interface ModalHeaderProps {
  /**
   * The title of the modal.
   */
  title: string;
  buttonText?: string;
  disabledCloseButton?: boolean;
}

export const Header = ({
  title,
  buttonText,
  disabledCloseButton,
}: ModalHeaderProps) => {
  return (
    <Styled.FlexHeader alignItems="center" justifyContent="center">
      <Dialog.Title asChild>
        <Text as="p" variant="lg-semibold">
          {title}
        </Text>
      </Dialog.Title>
      {!disabledCloseButton && (
        <Modal.Close>
          <button className="IconButton" aria-label="Close">
            <Text as="p" color="zinc_400">
              {buttonText ?? '닫기'}
            </Text>
          </button>
        </Modal.Close>
      )}
    </Styled.FlexHeader>
  );
};

export default Header;
