import React, { useCallback } from 'react';
import * as RadixToast from '@radix-ui/react-toast';
import { useAtom } from 'jotai';

import { toastsAtom } from './index.atom';
import * as Styled from './index.styles';

export const ToastContainer = React.memo(function Toast() {
  const [toasts, setToasts] = useAtom(toastsAtom);

  const handleOpenChange = useCallback(
    (open: boolean, id: string) => {
      if (!open) {
        setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
      }
    },
    [setToasts],
  );

  return (
    <RadixToast.Provider>
      {toasts.map(({ id, message, duration }) => (
        <Styled.ToastRoot
          key={id}
          duration={duration}
          onOpenChange={(open) => handleOpenChange(open, id)}
        >
          <RadixToast.Description asChild>
            <Styled.ToastDescriptionWrapper>
              {message}
            </Styled.ToastDescriptionWrapper>
          </RadixToast.Description>
        </Styled.ToastRoot>
      ))}
      <Styled.ToastViewport />
    </RadixToast.Provider>
  );
});
