import styled from '@emotion/styled';
import { Button } from '@pairy/pds-react';

export const ActiveButton = styled(Button)`
  &.is-active {
    border: 2px solid ${({ theme }) => theme.colors.skyblue_300};
    color: black;
    &:hover {
      border: 2px solid ${({ theme }) => theme.colors.skyblue_300};
      background-color: white;
      color: black;
    }
  }
`;
