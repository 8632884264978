import * as Tooltip from '@radix-ui/react-tooltip';
import { PropsWithChildren, useState } from 'react';

import { Flex, Icon, IconProps } from '@pairy/pds-react';

import Text from '../../Text';

import * as Styled from './index.styles';

export interface IconTooltipProps {
  /**
   * toggle 하는 대상의 아이콘 prop. 아이콘은 @iconify/react 를 사용합니다.
   *
   * @default {
   *   icon: 'ph:question-fill',
   *   size: 17,
   *   color: 'zinc_500',
   * }
   */
  iconProps?: IconProps;
}

const defaultIconProps: IconProps = {
  icon: 'ph:question-fill',
  size: 17,
  color: 'zinc_500',
};

export const IconTooltip = ({
  iconProps = defaultIconProps,
  children,
}: PropsWithChildren<IconTooltipProps>) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={0} open={isOpen}>
        <Tooltip.Trigger asChild onClick={(event) => event.preventDefault()}>
          <Flex
            width="fit-content"
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
          >
            <Icon {...iconProps} />
          </Flex>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Styled.TooltipContent sideOffset={10}>
            <Styled.ContentWrapper>
              <Text variant="sm-medium" color="white" textAlign="center">
                {children}
              </Text>
            </Styled.ContentWrapper>
          </Styled.TooltipContent>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export default IconTooltip;
