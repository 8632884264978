import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from '@pairy/styles';
import * as Dialog from '@radix-ui/react-dialog';

export const fadeInWithTanslate = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const Content = styled(Dialog.Content)<{
  width: string;
}>`
  position: fixed;

  z-index: 12;
  top: 50%;
  left: 50%;

  width: ${({ width }) => width};

  max-height: 90vh;

  background: ${theme.colors.white};
  border-radius: 8px;

  box-shadow: ${theme.elevation.md};
  gap: 24px;
  overflow-y: auto;
  transform: translate(-50%, -50%);

  &[data-state='open'] {
    animation: ${fadeInWithTanslate} 0.15s ease-in-out;
  }
`;

export const Overlay = styled(Dialog.Overlay)`
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(63 63 70 / 25%);

  &[data-state='open'] {
    animation: ${fadeIn} 0.15s ease-in-out;
  }
`;
