import {
  useMutation,
  type QueryKey,
  type UseMutationOptions,
  type UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { api } from '@/utils/api';
import { ApiErrorResponse, ApiResponse } from '@/types/api';

export interface DeleteCtaFormRequest {
  hashId: string;
}

interface UseDeleteCtaFormInvalidationParams {
  hashId: string;
  onSuccess: () => void;
  onError: () => void;
}

export const deleteCtaForm = ({ hashId }: DeleteCtaFormRequest) => {
  return api.delete<ApiResponse<undefined>>('/v2/file/form', {
    params: {
      hash: hashId,
    },
  });
};

export const useDeleteCtaForm = <TError = AxiosError<ApiErrorResponse>>(
  options?: UseMutationOptions<
    AxiosResponse<ApiResponse<undefined>>,
    TError,
    DeleteCtaFormRequest,
    QueryKey
  >,
): UseMutationResult<
  AxiosResponse<ApiResponse<undefined>>,
  TError,
  DeleteCtaFormRequest,
  QueryKey
> => {
  return useMutation(deleteCtaForm, {
    ...options,
  });
};

const useDeleteCtaFormWithInvalidation = ({
  hashId,
  onSuccess,
  onError,
}: UseDeleteCtaFormInvalidationParams) => {
  const queryClient = useQueryClient();

  const { mutate: deleteCtaFormMutate } = useDeleteCtaForm();

  const deleteFormHandler = (): void => {
    deleteCtaFormMutate(
      {
        hashId,
      },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries({
            queryKey: ['getCtaForm'],
          });

          onSuccess();
        },
        onError: () => {
          onError();
        },
      },
    );
  };
  return { mutate: deleteFormHandler };
};

export default useDeleteCtaFormWithInvalidation;
