import { Button, Modal, Stack, Text } from '@pairy/pds-react';
import cn from 'classnames';
import { useAtomValue } from 'jotai';

import { SetState } from '@/types/helper';
import CtaFormItem from '@/components/User/Pdf/ShareSetting/CtaSettingPage/SettingDrawer/CtaForm/CtaFormList/CtaFormItem';
import Section from '@/components/User/Pdf/ShareSetting/CtaSettingPage/SettingDrawer/CtaForm/CtaFormSetting/Section';
import SettingDropdown from '@/components/User/Pdf/ShareSetting/CtaSettingPage/SettingDrawer/CtaForm/CtaFormSetting/Main/CtaFormPageSetting/SettingDropdown';
import { ActiveButton } from '@/components/User/Pdf/ShareSetting/CtaSettingPage/SettingDrawer/CtaForm/CtaFormSetting/Main/CtaFormPageSetting/index.styles';
import { isShowUploadingModalAtom } from '@/state/file/uploadingfile.atom';
import useFileUploadList, {
  GetFileUploadListResponse,
} from '@/hooks/queries/file/useFileUploadList';

import usePrevCtaFormData from './usePrevCtaFormData';
import useCtaFormButtonsClick from './useCtaFormButtonsClick';
import useCtaFormPageDropdown from './useCtaFormPageDropdown';

interface Props {
  currentUploadFile: GetFileUploadListResponse[0];
}

const CtaFormSettingModal = ({ currentUploadFile }: Props) => {
  const isShowUploadingModal = useAtomValue(isShowUploadingModalAtom);

  const { formData, hash, isFormEditable, handleChangeFormDataWithKey } =
    usePrevCtaFormData({
      fileId: currentUploadFile.id,
    });

  const { data: fileData, refetch } = useFileUploadList({
    enabled: isShowUploadingModal,
    select: (data) => data.data.data,
  });

  const { dropdownItems } = useCtaFormPageDropdown({ fileData });

  const { handleCancelClick, handleApplyClick } = useCtaFormButtonsClick({
    hash,
    formData,
    refetch,
    fileId: currentUploadFile.id,
  });

  const handlePageChange = (page: number, setValue?: SetState<string>) => {
    if (!isFormEditable) {
      handleChangeFormDataWithKey('page', 1);
      if (setValue) {
        setValue(dropdownItems[0].label);
      }
      return;
    }

    if (setValue) {
      setValue(dropdownItems[page - 1].label);
    }

    handleChangeFormDataWithKey('page', page);
  };

  return (
    <Modal.Root open={!!currentUploadFile.id}>
      <Modal.Content onClick={(e) => e.stopPropagation()}>
        <Modal.Header title="문의양식 설정" disabledCloseButton />
        <Modal.Body>
          <Stack direction="column" justifyContent="center" gap={16}>
            <Text variant="xl-semibold" textAlign="center">
              {currentUploadFile.name}의
              <br />
              문의양식을 그대로 유지할까요?
            </Text>
            <Stack gap={16} alignItems="flex-start">
              {formData && (
                <CtaFormItem
                  name={formData.name}
                  fields={formData.fields}
                  page={formData.page}
                  blind={formData.blind}
                  disabledButtons
                />
              )}
              <Stack direction="column" gap={16}>
                <Section
                  title="문의양식이 보일 페이지"
                  notice={`Free 및 Basic Plan에서는 \n1페이지에만 문의양식을 더할 수 있습니다`}
                >
                  {formData && (
                    <SettingDropdown
                      initialValue={`${formData.page} 페이지`}
                      items={dropdownItems}
                      onValueChanges={(value, setValue) =>
                        handlePageChange(Number(value), setValue)
                      }
                    />
                  )}
                </Section>
                <Section title="가리기 설정">
                  <Stack direction="column" gap={8}>
                    <ActiveButton
                      variant="zinc_200"
                      paddingY={16}
                      width="100%"
                      className={cn({
                        'is-active': formData?.blind === 'none',
                      })}
                      onClick={() =>
                        handleChangeFormDataWithKey('blind', 'none')
                      }
                    >
                      가리기 없음
                    </ActiveButton>
                    <ActiveButton
                      variant="zinc_200"
                      paddingY={16}
                      width="100%"
                      className={cn({
                        'is-active': formData?.blind === 'specific',
                      })}
                      onClick={() =>
                        handleChangeFormDataWithKey('blind', 'specific')
                      }
                    >
                      {formData?.page} 페이지만 가리기
                    </ActiveButton>
                    <ActiveButton
                      variant="zinc_200"
                      paddingY={16}
                      width="100%"
                      className={cn({
                        'is-active': formData?.blind === 'startFrom',
                      })}
                      onClick={() =>
                        handleChangeFormDataWithKey('blind', 'startFrom')
                      }
                    >
                      {formData?.page} 페이지 이후 모두 가리기
                    </ActiveButton>
                  </Stack>
                </Section>
              </Stack>
            </Stack>
          </Stack>
        </Modal.Body>
        <Modal.Footer>
          <Stack gap={16}>
            <Button
              width="100%"
              variant={'zinc_200'}
              paddingY={16}
              onClick={() => {
                handleCancelClick();
              }}
            >
              아니오, 삭제할래요
            </Button>
            <Button
              width="100%"
              variant={'skyblue_500_fill'}
              paddingY={16}
              onClick={handleApplyClick}
            >
              네, 유지할게요
            </Button>
          </Stack>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};

export default CtaFormSettingModal;
