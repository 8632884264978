import { useAtom } from 'jotai';
import { useEffect } from 'react';

import { isTriggerUploadingRequestAtom } from '@/state/file/uploadingfile.atom';

interface Props {
  refetch: () => void;
}

const useHandleTriggerUploadRequest = ({ refetch }: Props) => {
  const [isTriggerUploadingRequest, setIsTriggerUploadingRequest] = useAtom(
    isTriggerUploadingRequestAtom,
  );

  useEffect(() => {
    if (isTriggerUploadingRequest) {
      refetch();
      setIsTriggerUploadingRequest(false);
    }
  }, [isTriggerUploadingRequest]);
};

export default useHandleTriggerUploadRequest;
