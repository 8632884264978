import React, { PropsWithChildren, forwardRef } from 'react';

import Stack from '../Stack';

import * as Styled from './index.styles';
import { Props } from './index.types';

export const Button = forwardRef(function Button(
  {
    variant = 'default',
    backgroundColor = 'inherit',
    textVariant = 'md-regular',
    radius = 8,
    marginTop = 0,
    marginRight = 0,
    marginBottom = 0,
    marginLeft = 0,
    paddingX = 16,
    paddingY = 8,
    width = 'auto',
    leftIcon,
    rightIcon,
    children,
    borderLess = false,
    InnerWrappeGap = 8,
    innerStyle = {},
    maxContentWidth = false,
    id,
    ...rest
  }: PropsWithChildren<Props>,
  forwardedRef: React.Ref<HTMLButtonElement>,
) {
  return (
    <Styled.Button
      variant={variant}
      backgroundColor={backgroundColor}
      radius={radius}
      marginTop={marginTop}
      marginRight={marginRight}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      paddingX={paddingX}
      paddingY={paddingY}
      maxContentWidth={maxContentWidth}
      width={width}
      textVariant={textVariant}
      borderLess={borderLess}
      ref={forwardedRef}
      id={id}
      {...rest}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        gap={InnerWrappeGap}
        style={innerStyle}
        id={id}
      >
        {leftIcon && leftIcon}
        {children}
        {rightIcon && rightIcon}
      </Stack>
    </Styled.Button>
  );
});

export default Button;
