import { theme } from '@pairy/styles';

import * as Styled from './index.styles';

interface Props {
  className?: string;
  color?: keyof typeof theme.colors;
}

export const Spinner = ({ className, color = 'skyblue_400' }: Props) => {
  return (
    <Styled.Svg
      color={theme.colors[color]}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      role="img"
      width="64"
      height="64"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 256 256"
    >
      <path
        fill="currentColor"
        d="M232 128A104 104 0 1 1 84.7 33.4a8.1 8.1 0 0 1 10.6 4a8 8 0 0 1-4 10.6a88 88 0 1 0 73.4 0a8 8 0 0 1-4-10.6a8.1 8.1 0 0 1 10.6-4A104.4 104.4 0 0 1 232 128Z"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="1s"
          from="0 128 128"
          to="360 128 128"
          repeatCount="indefinite"
        />
      </path>
    </Styled.Svg>
  );
};

export default Spinner;
