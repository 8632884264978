import { ReactNode } from 'react';

import { Icon } from '../../Icon';

import * as Styled from './index.styles';

interface ToastProps {
  message: ReactNode;
}

const SuccessToast = ({ message }: ToastProps) => {
  return (
    <Styled.Container gap={17.5} padding={{ x: 32, y: 16 }}>
      <Icon icon="ph:seal-check" size={19} />
      <Styled.TextWrapper variant="lg-medium">{message}</Styled.TextWrapper>
    </Styled.Container>
  );
};

const ErrorToast = ({ message }: ToastProps) => {
  return (
    <Styled.ErrorContainer gap={17.5} padding={{ x: 32, y: 16 }}>
      <Icon icon="ph:warning-circle" size={19} color="cerise_500" />
      <Styled.TextWrapper variant="lg-medium" color="cerise_500">
        {message}
      </Styled.TextWrapper>
    </Styled.ErrorContainer>
  );
};

export const ToastMessage = {
  success: SuccessToast,
  error: ErrorToast,
};
