import styled from '@emotion/styled';
import { theme } from '@pairy/styles';

import { Props } from './index.types';

export const Wrapper = styled.div<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: ${({ paddingX, paddingY }) => `${paddingY}px ${paddingX}px`};
  border: 1px solid ${({ borderColor }) => theme.colors[borderColor]};
  border-style: ${({ borderStyle }) => borderStyle};
  margin: ${({ marginTop, marginRight, marginBottom, marginLeft }) =>
    `${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px`};
  background-color: ${({ backgroundColor }) => theme.colors[backgroundColor]};

  border-radius: ${({ radius }) => `${radius}px`};
  color: ${({ color }) => theme.colors[color]};
  font-size: 10px;
  font-weight: 500;
  gap: 4px;
  line-height: 150%;
`;
