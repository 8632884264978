import { useSetAtom } from 'jotai';
import { CtaForm } from '@pairy/sheard/types';
import { useToast } from '@pairy/pds-react';

import useHandleFileUploadDone from '@/components/Layouts/FileUploadingElements/useHandleFileUploadDone';
import { notSetCtaFormFileIdListAtom } from '@/state/file/uploadingfile.atom';
import usePostCtaForm from '@/hooks/mutations/file/usePostCtaForm';
import { useDeleteCtaForm } from '@/hooks/mutations/file/useDeleteCtaForm';

interface Props {
  hash?: string;
  formData: CtaForm | null;
  refetch: () => void;
  fileId: number;
}

const useCtaFormButtonsClick = ({ hash, formData, refetch, fileId }: Props) => {
  const toast = useToast();

  const mutateOptions = {
    onSuccess: () => {
      toast('파일 수정이 완료되었습니다');
    },
    onError: () => {
      toast('파일 수정에 실패했습니다');
    },
  };

  const { mutate: postCtaFormMutate } = usePostCtaForm(mutateOptions);
  const { mutate: deleteCtaFormMutate } = useDeleteCtaForm(mutateOptions);

  const setNotSetCtaFormFileIdList = useSetAtom(notSetCtaFormFileIdListAtom);

  const { handleCloseButtonClick, handleSuccessFileCheckClick } =
    useHandleFileUploadDone({ refetch });

  const postCtaForm = () => {
    if (!hash || !formData) {
      return;
    }

    postCtaFormMutate({
      hash,
      form: formData,
    });
  };

  const deleteCtaForm = () => {
    if (!hash) {
      return;
    }

    deleteCtaFormMutate({
      hashId: hash,
    });
  };

  const handleCancelClick = () => {
    setNotSetCtaFormFileIdList((prev) =>
      prev.filter((item) => item.id !== fileId),
    );

    deleteCtaForm();

    handleCloseButtonClick(fileId);
  };

  const handleApplyClick = () => {
    setNotSetCtaFormFileIdList((prev) =>
      prev.filter((item) => item.id !== fileId),
    );
    postCtaForm();

    handleSuccessFileCheckClick(fileId);
  };

  return {
    handleCancelClick,
    handleApplyClick,
  };
};

export default useCtaFormButtonsClick;
