import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useToast } from '@pairy/pds-react';

import {
  UploadingFileInfoListAtom,
  createSuccessFileIdListAtom,
  notSetCtaFormFileIdListAtom,
} from '@/state/file/uploadingfile.atom';
import useFileUploadList, {
  FileUploadingItemType,
} from '@/hooks/queries/file/useFileUploadList';

interface Props {
  isShowUploadingModal: boolean;
}

const useHandleUploadingFilesState = ({ isShowUploadingModal }: Props) => {
  const queryClient = useQueryClient();

  const toast = useToast();

  const [uploadingFileList, setUploadingFileList] = useAtom(
    UploadingFileInfoListAtom,
  );

  const [notSetCtaFormFileIdList, setNotSetCtaFormFileIdList] = useAtom(
    notSetCtaFormFileIdListAtom,
  );
  const [createSuccessFileIdList, setCreateSuccessFileIdList] = useAtom(
    createSuccessFileIdListAtom,
  );

  const { refetch, data } = useFileUploadList({
    enabled: isShowUploadingModal,
    select: (data) => data.data.data,
    refetchInterval: (res) => {
      const filterUploadingFile = res?.filter(
        (item) =>
          item.uploadStatus !== 'Success' && item.uploadStatus !== 'Error',
      );

      /*모달을 보여주지 않을 때는 요청을 보내지 않는다 */
      return isShowUploadingModal && filterUploadingFile?.length !== 0
        ? 1500
        : false;
    },
  });

  useEffect(() => {
    if (typeof data === 'undefined') {
      setUploadingFileList([]);
      return;
    }

    /** Api로 삽입한 데이터 제외 */
    const filterOpenApiData = data.filter((item) => !item.isFromApi);

    setUploadingFileList(filterOpenApiData);
  }, [data]);

  const handleUpdateFileList = (updateFileList: FileUploadingItemType[]) => {
    updateFileList.forEach((item) => {
      if (
        typeof notSetCtaFormFileIdList.find(
          (formItem) => formItem.id === item.id,
        ) === 'undefined'
      ) {
        setNotSetCtaFormFileIdList((prev) => [...prev, item]);
      }
    });
  };

  const handleCreateFileList = (createFileList: FileUploadingItemType[]) => {
    createFileList.forEach((item) => {
      if (
        typeof createSuccessFileIdList.find((id) => item.id === id) ===
        'undefined'
      ) {
        setCreateSuccessFileIdList((prev) => [...prev, item.id]);
        toast('파일 업로드가 완료되었습니다');
      }
    });
  };

  useEffect(() => {
    /** 캐싱되므로, API 호출시마다 동작되도 큰 문제 없다고 판단 */
    queryClient.refetchQueries({ queryKey: ['getFileList'], exact: false });

    const successElements = uploadingFileList.filter(
      (item) => item.uploadStatus === 'Success',
    );

    if (successElements.length > 0) {
      handleUpdateFileList(
        successElements.filter((item) => item.isFormRequired),
      );

      handleCreateFileList(
        successElements.filter((item) => !item.isFormRequired),
      );
    }

    const failElement = uploadingFileList.find(
      (item) => item.uploadStatus === 'Error',
    );
    if (failElement) {
      toast.error('파일 업로드에 실패했습니다');
    }
  }, [uploadingFileList]);

  return {
    refetch,
    data,
    uploadingFileList,
  };
};

export default useHandleUploadingFilesState;
