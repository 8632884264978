import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { ApiErrorResponse, ApiResponse } from '@/types/api';
import { api } from '@/utils/api';

export type FileUploadStatusType =
  | 'Start'
  | `Convert:${number}`
  | 'Error'
  | 'Success';

export interface FileUploadingItemType {
  id: number;
  name: string;
  pages: number;
  uploadStatus: FileUploadStatusType;
  isBulk: boolean;
  isFormRequired: boolean;
  isFromApi: boolean;
}

export type GetFileUploadListResponse = FileUploadingItemType[];

const getFileUploadList = () => {
  return api.get<ApiResponse<GetFileUploadListResponse>>('/v2/file/upload');
};

const useFileUploadList = <
  TData = AxiosResponse<ApiResponse<GetFileUploadListResponse>>,
  TError = AxiosError<ApiErrorResponse>,
>(
  options?: UseQueryOptions<
    AxiosResponse<ApiResponse<GetFileUploadListResponse>>,
    TError,
    TData,
    ['getFileUploadList']
  >,
): UseQueryResult<TData, TError> => {
  return useQuery(['getFileUploadList'], () => getFileUploadList(), {
    ...options,
  });
};

export default useFileUploadList;
