import { Children, PropsWithChildren } from 'react';

import * as Styled from './index.styles';
import type { Props } from './index.types';

export const Breadcrumbs = ({
  className,
  children,
  separator,
  spacing,
}: PropsWithChildren<Props>) => {
  const items = Children.toArray(children).map((child, index, arr) => {
    if (index !== arr.length - 1) {
      return (
        <Styled.Item key={index} spacing={spacing}>
          <span>{child}</span>
          {separator}
        </Styled.Item>
      );
    }

    return <span key={index}>{child}</span>;
  });
  return (
    <Styled.Wrapper className={className} spacing={spacing}>
      {items}
    </Styled.Wrapper>
  );
};

export default Breadcrumbs;
