import styled from '@emotion/styled';
import { theme } from '@pairy/styles';

import { TagProps, variants } from './index.types';

export const Wrapper = styled.div<
  Required<Omit<TagProps, 'textVariant' | 'gap' | 'leftIcon'>>
>`
  width: fit-content;
  border-width: ${({ borderWidth }) => `${borderWidth}`};
  border-style: solid;
  border-color: ${({ borderColor }) => `${theme.colors[borderColor]}`};

  margin: ${({ marginTop, marginRight, marginBottom, marginLeft }) =>
    `${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px`};

  ${({ variant }) => {
    const { backgroundColor } = variants[variant];

    return `
      display: inline-block;
      padding: 4px 8px;
      border-radius: 4px;
      background-color: ${theme.colors[backgroundColor]};
    `;
  }};
`;

export const LeftIconWrapper = styled.div<Required<Pick<TagProps, 'gap'>>>`
  margin-right: ${({ gap }) => gap}px;
`;
