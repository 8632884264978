import { theme } from '@pairy/styles';
import React from 'react';

export const variants = {
  loyalblue_50: { backgroundColor: 'loyalblue_50', fontColor: 'loyalblue_500' },
  zinc_100: { backgroundColor: 'zinc_100', fontColor: 'zinc_400' },
  zinc_400: { backgroundColor: 'white', fontColor: 'zinc_400' },
  vermillion_50: {
    backgroundColor: 'vermillion_50',
    fontColor: 'vermillion_500',
  },
  vermillion_400: {
    backgroundColor: 'vermillion_400',
    fontColor: 'white',
  },
  viridian_50: { backgroundColor: 'viridian_50', fontColor: 'viridian_500' },
  viridian_500: { backgroundColor: 'viridian_500', fontColor: 'white' },
  skyblue_50: { backgroundColor: 'skyblue_50', fontColor: 'skyblue_500' },
  skyblue_400: { backgroundColor: 'white', fontColor: 'skyblue_400' },
  skyblue_400_fill: { backgroundColor: 'skyblue_400', fontColor: 'white' },
} as const;

export type Variant = keyof typeof variants;

export interface TagProps {
  /**
   * Tag의 엘리먼트 타입을 설정합니다.
   *
   * @default 'div'
   */
  as?: React.ElementType;

  /**
   * Tag의 색상을 설정합니다.
   */
  variant: Variant;

  /**
   * Tag의 폰트 크기를 설정합니다.
   *
   * @default 'md-regular'
   */
  textVariant?: keyof typeof theme.textVariant;

  /**
   * Tag의 왼쪽 아이콘을 설정합니다.
   */
  leftIcon?: React.ReactNode;

  /**
   * 아이콘과 텍스트 사이의 간격을 설정합니다.
   *
   * @default 4
   */
  gap?: number;

  /**
   * margin 상단을 설정합니다.
   *
   * @default 0
   */
  marginTop?: number;

  /**
   * margin 우측을 설정합니다.
   *
   * @default 0
   */
  marginRight?: number;

  /**
   * margin 하단을 설정합니다.
   *
   * @default 0
   */
  marginBottom?: number;

  /**
   * margin 좌측을 설정합니다.
   *
   * @default 0
   */
  marginLeft?: number;

  /**
   * border color를 설정합니다.
   *
   * @default 'inherit'
   */
  borderColor?: keyof typeof theme.colors;

  /**
   * border width를 설정합니다.
   *
   * @default '0'
   */
  borderWidth?: string;
}

export interface Props extends React.HTMLAttributes<HTMLDivElement>, TagProps {}
