import { Icon, Stack, Text } from '@pairy/pds-react';
import { useAtomValue, useSetAtom } from 'jotai';
import { CtaForm } from '@pairy/sheard/types';

import useDeleteCtaFormWithInvalidation from '@/hooks/mutations/file/useDeleteCtaForm';

import {
  ctaFormHashAtom,
  ctaSettingTypeAtom,
  currentPageAtom,
} from '../../../../index.atom';
import { isCtaFormOpenAtom } from '../../../../form.atom';

import * as Styled from './index.styles';

interface Props {
  name: CtaForm['name'];
  fields: CtaForm['fields'];
  page: CtaForm['page'];
  blind: CtaForm['blind'];
  disabledButtons?: boolean;
}

const CtaFormItem = ({ name, fields, page, blind, disabledButtons }: Props) => {
  const ctaFormHash = useAtomValue(ctaFormHashAtom);
  const setCtaSettingType = useSetAtom(ctaSettingTypeAtom);
  const setCurrentPage = useSetAtom(currentPageAtom);
  const setCtaFormOpen = useSetAtom(isCtaFormOpenAtom);

  const { mutate: handleDeleteCtaForm } = useDeleteCtaFormWithInvalidation({
    hashId: ctaFormHash as string,
    onSuccess: () => {
      setCurrentPage(1);
    },
    onError: () => undefined,
  });

  const handleMoveToEdit = () => {
    setCtaSettingType('cta-form');
  };

  const blindMap = {
    none: '가리기 없음',
    specific: `${page} 페이지만 가리기`,
    startFrom: `${page} 페이지 이후 모두 가리기`,
  };

  return (
    <Styled.Container
      gap={16}
      direction="column"
      padding={16}
      onClick={() => {
        setCurrentPage(page);
        setCtaFormOpen(true);
      }}
    >
      <Stack gap={4} direction="column">
        <Text variant="lg-semibold" color="black">
          {name}
        </Text>
        <Text variant="md-semibold" color="black">
          {page} 페이지
        </Text>
      </Stack>
      <Stack gap={4} direction="column">
        <Text variant="sm-semibold" color="zinc_400">
          가리기 옵션
        </Text>
        <Text variant="md-medium" color="zinc_500">
          {blindMap[blind]}
        </Text>
      </Stack>
      <Stack gap={4} direction="column">
        <Text variant="sm-semibold" color="zinc_400">
          받는 정보
        </Text>
        <Styled.TruncateText variant="md-medium" color="zinc_500">
          {fields.map((field) => field.name).join(', ')}
        </Styled.TruncateText>
        <Text variant="sm-medium" color="zinc_500">
          총 {fields.length}개
        </Text>
      </Stack>
      {!disabledButtons && (
        <Stack gap={8} justifyContent="flex-end">
          <button type="button" onClick={handleDeleteCtaForm}>
            <Stack gap={4} padding={4}>
              <Icon icon="ph:trash" color="cerise_300" size={17} />
              <Text variant="sm-medium" color="cerise_300">
                삭제
              </Text>
            </Stack>
          </button>
          <button type="button" onClick={handleMoveToEdit}>
            <Stack gap={4} padding={4}>
              <Icon icon="ph:note-pencil" color="zinc_400" size={17} />
              <Text variant="sm-medium" color="zinc_400">
                수정
              </Text>
            </Stack>
          </button>
        </Stack>
      )}
    </Styled.Container>
  );
};

export default CtaFormItem;
