import styled from '@emotion/styled';

import type { BreadcrumbsProps } from './index.types';

export const Wrapper = styled.div<Pick<BreadcrumbsProps, 'spacing'>>`
  display: flex;
  gap: ${({ spacing }) => spacing}px;
`;

export const Item = styled.div<Pick<BreadcrumbsProps, 'spacing'>>`
  display: flex;
  align-items: center;
  gap: ${({ spacing }) => spacing}px;
  opacity: 0.5;
`;
