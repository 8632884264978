import React, {
  ElementType,
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
} from 'react';
import { theme } from '@pairy/styles';

import * as Styled from './index.styles';

interface Props extends Omit<HTMLAttributes<HTMLElement>, 'title'> {
  as?: ElementType;
  title?: ReactNode;
  rightIcon?: ReactNode;
  padding?: string;
  bottomCTAButton?: ReactNode;
  isTitleImage?: boolean;
  isMultipleCTAButtons?: boolean;
  borderColor?: keyof typeof theme.colors;
  backgroundColor?: keyof typeof theme.colors;
  width?: string;
  overflow?: React.CSSProperties['overflow'];
}

export const Box = ({
  as = 'div',
  title,
  children,
  className,
  rightIcon,
  padding = '16px',
  bottomCTAButton,
  isTitleImage = false,
  isMultipleCTAButtons = false,
  borderColor = 'zinc_200',
  backgroundColor = 'white',
  width = 'auto',
  overflow = 'auto',
  ...rest
}: PropsWithChildren<Props>) => {
  return (
    <Styled.Wrapper
      as={as}
      className={className}
      padding={padding}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      width={width}
      {...rest}
    >
      {title && (
        <Styled.TitleWrapper padding={padding} isTitleImage={isTitleImage}>
          {title}
        </Styled.TitleWrapper>
      )}

      <Styled.ContentWrapper padding={padding} hasTitle={Boolean(title)}>
        <Styled.ContentInnerWrapper overflow={overflow}>
          {children}
        </Styled.ContentInnerWrapper>
        {rightIcon && <div id="box-icon-wrapper">{rightIcon}</div>}
      </Styled.ContentWrapper>

      {bottomCTAButton && (
        <Styled.BottomCTAButtonWrapper
          className="bottom-CTA-button-wrapper"
          isMultipleCTAButtons={isMultipleCTAButtons}
        >
          {bottomCTAButton}
        </Styled.BottomCTAButtonWrapper>
      )}
    </Styled.Wrapper>
  );
};

export default Box;
