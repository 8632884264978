import React from 'react';

import { SkeletonProps } from './index.types';
import * as Styled from './index.styles';

export const Skeleton = React.forwardRef<HTMLDivElement, SkeletonProps>(
  function Skeleton(
    {
      as = 'div',
      variants = 'rectangular-rounded',
      height,
      width,
      marginBottom = 0,
      marginLeft = 0,
      marginRight = 0,
      marginTop = 0,
      paddingBottom = 0,
      paddingLeft = 0,
      paddingRight = 0,
      paddingTop = 0,
      ...rest
    },
    forwardedRef,
  ) {
    return (
      <Styled.Wrapper
        as={as}
        variants={variants}
        height={height}
        width={width}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        marginRight={marginRight}
        marginTop={marginTop}
        paddingBottom={paddingBottom}
        paddingLeft={paddingLeft}
        paddingRight={paddingRight}
        paddingTop={paddingTop}
        ref={forwardedRef}
        {...rest}
      />
    );
  },
);

export default Skeleton;
