import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';

export interface ModalRootProps extends Dialog.DialogProps {}

export const Root = ({
  children,
  open,
  onOpenChange,
}: React.PropsWithChildren<ModalRootProps>) => {
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      {children}
    </Dialog.Root>
  );
};

export default Root;
