import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

const GoogleTagManager = () => {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID) {
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
      });
    }
  }, []);

  return <></>;
};

export default GoogleTagManager;
