import { Flex, Icon, Stack, Text } from '@pairy/pds-react';
import { AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import { useAtom, useAtomValue } from 'jotai';

import {
  isShowUploadingModalAtom,
  notSetCtaFormFileIdListAtom,
} from '@/state/file/uploadingfile.atom';
import { FileUploadingItemType } from '@/hooks/queries/file/useFileUploadList';

import * as Styled from './index.styles';
import CtaFormSettingModal from './CtaFormSettingModal';
import ProgressBar from './ProgreeBar';
import PageStatus from './PageStatus';
import { UploadStatus } from './UploadStatus';
import useHandleUploadingFilesState from './useHandleUploadingFilesState';
import useHandleNotShowingUploadingModal from './useHandleNotShowingUploadingModal';
import useHandleFileUploadDone from './useHandleFileUploadDone';
import useHandleTriggerUploadRequest from './useHandleTriggerUploadRequest';
import useHandleCreateSuccessModalClose from './useHandleCreateSuccessModalClose';

const FileUploadingElements = () => {
  const notSetCtaFormFileIdList = useAtomValue(notSetCtaFormFileIdListAtom);

  const isCtaFormSettingModalOpen = notSetCtaFormFileIdList.length > 0;

  const router = useRouter();
  const pathname = router.pathname;

  const isInvalidPage = () => {
    return (
      pathname.includes('cta-setting') ||
      pathname.includes('pdf-preview') ||
      pathname.includes('login')
    );
  };

  const [isShowUploadingModal, setIsShowUploadingModal] = useAtom(
    isShowUploadingModalAtom,
  );

  const { refetch, uploadingFileList } = useHandleUploadingFilesState({
    isShowUploadingModal,
  });

  useHandleNotShowingUploadingModal({
    setIsShowUploadingModal,
    isInvalidPage,
    pathname,
    refetch,
  });

  const { handleCloseButtonClick, handleSuccessFileCheckClick } =
    useHandleFileUploadDone({ refetch });

  useHandleTriggerUploadRequest({ refetch });

  useHandleCreateSuccessModalClose({ handleCloseButtonClick });

  return (
    <>
      <AnimatePresence mode="wait">
        {uploadingFileList.length >= 1 && isShowUploadingModal && (
          <Styled.Container>
            {uploadingFileList?.map(
              ({ id, name, uploadStatus, pages, isBulk }) => (
                <Styled.Wrapper
                  key={id}
                  initial={{ translateY: 100, opacity: 0 }}
                  animate={{ translateY: 0, opacity: 1 }}
                  exit={{ translateY: 100, opacity: 0 }}
                  transition={{ duration: 0.25, ease: 'easeInOut' }}
                >
                  <Stack gap={16} direction="column">
                    <Flex
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Stack gap={8} direction="column" width="fit-content">
                        <Text variant="sm-medium" color="zinc_400">
                          업로드 중인 파일
                        </Text>
                        <Styled.TextWrapper variant="lg-semibold">
                          {name}
                        </Styled.TextWrapper>
                      </Stack>

                      <Stack gap={16} width="fit-content">
                        {(uploadStatus === 'Success' ||
                          uploadStatus === 'Error') && (
                          <button
                            type="button"
                            onClick={() => {
                              handleCloseButtonClick(id);
                            }}
                          >
                            <Stack gap={4} alignItems="center">
                              <Icon icon="ph:x" color="zinc_400" size={14} />
                              <Text variant="md-medium" color="zinc_400">
                                닫기
                              </Text>
                            </Stack>
                          </button>
                        )}

                        {uploadStatus === 'Success' && (
                          <Styled.BlueButton
                            onClick={() => {
                              handleSuccessFileCheckClick(id);
                            }}
                          >
                            <Text variant="md-medium" color="white">
                              {isBulk ? '파일 확인하기' : '고객 추가하기'}
                            </Text>
                          </Styled.BlueButton>
                        )}
                      </Stack>
                    </Flex>

                    <Flex justifyContent="space-between">
                      <ProgressBar
                        totalPage={pages}
                        uploadStatus={uploadStatus}
                      />
                      {uploadStatus.includes('Convert') && (
                        <PageStatus
                          totalPage={pages}
                          uploadStatus={uploadStatus}
                        />
                      )}
                      {uploadStatus === 'Success' && <UploadStatus.success />}
                      {uploadStatus === 'Error' && <UploadStatus.fail />}
                      {uploadStatus === 'Start' && <UploadStatus.pending />}
                    </Flex>
                  </Stack>
                </Styled.Wrapper>
              ),
            )}
          </Styled.Container>
        )}
      </AnimatePresence>
      {isCtaFormSettingModalOpen && !isInvalidPage() && (
        <CtaFormSettingModal
          key={notSetCtaFormFileIdList[0]?.id}
          currentUploadFile={
            notSetCtaFormFileIdList[0] as FileUploadingItemType
          }
        />
      )}
    </>
  );
};

export default FileUploadingElements;
