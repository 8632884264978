import { Root, type ModalRootProps } from './Root';
import { Header, type ModalHeaderProps } from './Header';
import { Footer } from './Footer';
import { Body, type ModalBodyProps } from './Body';
import { Content, type ModalContentProps } from './Content';
import { Trigger } from './Trigger';
import { Close } from './Close';
import { Spinner } from './Loading/Spinner';

export const Modal = {
  Root,
  Header,
  Footer,
  Body,
  Content,
  Trigger,
  Close,
  Spinner,
};

export type {
  ModalRootProps,
  ModalHeaderProps,
  ModalBodyProps,
  ModalContentProps,
};
