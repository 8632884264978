import { atom } from 'jotai';
import { CtaForm, FormFieldType } from '@pairy/sheard/types';

export type CurrentCtaFormSettingType = 'newQuestion' | FormFieldType | null;

/* NOTE: CtaForm이 Open되어있는지 여부 */
export const isCtaFormOpenAtom = atom(false);

/* NOTE: formData 관리 (초기 마운트, 질문 수정, 질문 추가 등 범용적 사용) */
export const formDataAtom = atom<CtaForm | null>(null);

/**
 * NOTE: CtaForm의 초기 데이터 관리
 * 서버 데이터가 있으면 저장 전까진 서버 데이터, 없으면 기본 데이터
 * 관련 컴포넌트: <CtaFormModal />
 */
export const InitialFormDataAtom = atom<CtaForm | null>(null);

/* NOTE: 현재 Setting Drawer 콘텐츠 타입에 대한 Atom */
export const currentCtaFormSettingTypeAtom =
  atom<CurrentCtaFormSettingType>(null);

/* NOTE: 질문을 수정할 때 Drawer Header에 표시할 Order를 위한 전역 상태 */
export const currentEditQuestionOrderAtom = atom<number>(1);

/* NOTE: 새롭게 만드는 Cta Form 타입에 대한 전역 상태 (기본값 Select) */
export const newCtaFormTypeAtom = atom<FormFieldType>('select');
