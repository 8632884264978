import { Stack, Text } from '@pairy/pds-react';
import styled from '@emotion/styled';

export const Container = styled(Stack)`
  border: 1px solid ${({ theme }) => theme.colors.zinc_200};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.elevation.xs};
`;

export const TruncateText = styled(Text)`
  overflow: hidden;
  width: 223px;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
`;
