import { useRouter } from 'next/router';
import { useEffect } from 'react';

import * as gtag from '@/utils/gtag';

// type ref : https://developers.channel.io/reference/web-quickstart-kr#typescript%EB%A5%BC-%EC%9C%84%ED%95%9C-service
declare global {
  interface Window {
    ChannelIO?: IChannelIO;
    ChannelIOInitialized?: boolean;
  }
}

interface IChannelIO {
  c?: (...args: any) => void;
  q?: [methodName: string, ...args: any[]][];
  (...args: any): void;
}

const usePageView = () => {
  const router = useRouter();

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID &&
      process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID &&
      process.env.NEXT_PUBLIC_CHANNEL_IO_KEY
    ) {
      const handleRouteChange = (url: string) => {
        gtag.pageview(url);

        if (window !== undefined && window.ChannelIO) {
          window.ChannelIO('setPage', url);
          window.ChannelIO('track', 'PageView');
        }
      };

      router.events.on('routeChangeComplete', handleRouteChange);
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }
  }, [router.events]);
};

export default usePageView;
