import { type SwitchProps } from '@radix-ui/react-switch';
import React from 'react';

import * as Styled from './index.styles';

interface Props extends SwitchProps {}

export const Switch = React.forwardRef(function Switch(
  props: Props,
  forwardedRef: React.Ref<HTMLButtonElement>,
) {
  return (
    <Styled.SwitchRoot {...props} ref={forwardedRef}>
      <Styled.SwitchThumb />
    </Styled.SwitchRoot>
  );
});

export default Switch;
