import styled from '@emotion/styled';
import { theme } from '@pairy/styles';
import * as Tooltip from '@radix-ui/react-tooltip';

export const TooltipContent = styled(Tooltip.Content)`
  z-index: 50;
`;

export const ContentWrapper = styled.div`
  padding: 8px;

  background: ${theme.colors.zinc_500};
  border-radius: 4px;
  box-shadow: ${theme.elevation.md};
`;
