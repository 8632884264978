import { useSetAtom } from 'jotai';
import React, { ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ToastMessage } from './ToastMessage';
import { toastsAtom } from './index.atom';

interface ToastOptions {
  duration?: number;
}

const defaultDuration = 3000;

export const useToast = () => {
  const setToasts = useSetAtom(toastsAtom);

  const addToast = (children: React.ReactNode, options?: ToastOptions) => {
    const duration = options?.duration ?? defaultDuration;

    setToasts((prev) => [
      ...prev,
      {
        id: uuidv4(),
        duration,
        message: children,
      },
    ]);
  };

  const toast = (children: ReactNode, options = { duration: 2000 }) => {
    addToast(<ToastMessage.success message={children} />, options);
  };

  toast.error = (children: ReactNode, options = { duration: 2000 }) => {
    addToast(<ToastMessage.error message={children} />, options);
  };

  return toast;
};
