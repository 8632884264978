import { useEffect } from 'react';

import { SetState } from '@/types/helper';

interface Props {
  setIsShowUploadingModal: SetState<boolean>;
  isInvalidPage: () => boolean;
  pathname: string;
  refetch: () => void;
}
const useHandleNotShowingUploadingModal = ({
  setIsShowUploadingModal,
  isInvalidPage,
  pathname,
  refetch,
}: Props) => {
  useEffect(() => {
    if (isInvalidPage()) {
      setIsShowUploadingModal(false);
      return;
    }
    setIsShowUploadingModal(true);
    refetch();
  }, [pathname]);
};

export default useHandleNotShowingUploadingModal;
