import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';

import Loading from '../Loading';

import * as Styled from './index.styles';

const modalWidth = {
  default: '562px',
  template: '698px',
  wide: '891px',
} as const;
export type ModalWidth = keyof typeof modalWidth;

export interface ModalContentProps extends Dialog.DialogContentProps {
  /**
   * The width of the modal.
   *
   * @default 562px
   */
  width?: ModalWidth | number;

  /**
   * Whether the modal is loading or not.
   *
   * @default false
   */
  loading?: boolean | React.ReactNode;

  isOutsideClickClosed?: boolean;
}

export const Content = ({
  children,
  loading = false,
  width = 'default',
  isOutsideClickClosed = true,
  ...rest
}: React.PropsWithChildren<ModalContentProps>) => {
  const getLoading = () => {
    if (typeof loading === 'boolean') {
      return loading ? <Loading /> : null;
    }

    return React.isValidElement(loading) ? <Loading>{loading}</Loading> : null;
  };

  return (
    <Dialog.Portal>
      <Styled.Overlay />
      <Styled.Content
        width={typeof width === 'number' ? `${width}px` : modalWidth[width]}
        onInteractOutside={(e) => {
          if (isOutsideClickClosed) {
            e.preventDefault();
          }
        }}
        {...rest}
      >
        {children}
        {getLoading()}
      </Styled.Content>
    </Dialog.Portal>
  );
};

export default Content;
