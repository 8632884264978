import { useEffect } from 'react';
import ReactGA from 'react-ga4';

const GoogleAnalytics = () => {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize([
        { trackingId: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID },
      ]);
    }
  }, []);

  return <></>;
};

export default GoogleAnalytics;
