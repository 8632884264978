import { forwardRef, HTMLAttributes, Ref } from 'react';

import * as Styled from './index.styles';
import { DotProps } from './index.types';

interface Props extends HTMLAttributes<HTMLSpanElement>, DotProps {
  color: DotProps['color'];
}

export const Dot = forwardRef(function Dot(
  {
    color,
    size = 5,
    marginTop = 0,
    marginRight = 0,
    marginBottom = 0,
    marginLeft = 0,
    ...rest
  }: Props,
  forwardedRef: Ref<HTMLButtonElement>,
) {
  return (
    <Styled.Wrapper
      color={color}
      size={size}
      marginTop={marginTop}
      marginRight={marginRight}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      ref={forwardedRef}
      {...rest}
    />
  );
});

export default Dot;
