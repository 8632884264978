import styled from '@emotion/styled';
import { theme } from '@pairy/styles';

export const Svg = styled.svg`
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  color: ${theme.colors.skyblue_400};
  transform: translate(-50%, -50%);
`;
