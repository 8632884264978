import styled from '@emotion/styled';
import { theme } from '@pairy/styles';

import Text from '../../Text';
import Stack from '../../Stack';

export const Container = styled(Stack)`
  background-color: ${theme.colors.white};
  border-radius: 9999px;
  box-shadow: ${theme.elevation.md};
`;

export const ErrorContainer = styled(Stack)`
  border: 1px solid ${theme.colors.cerise_500};
  background-color: ${theme.colors.cerise_50};

  border-radius: 9999px;
  box-shadow: ${theme.elevation.md};
`;

export const TextWrapper = styled(Text)`
  white-space: pre-wrap;
`;
