import { PropsWithChildren } from 'react';
import { DialogProps } from '@radix-ui/react-dialog';

import Button from '../../Button';
import { Modal } from '../Modal';

export interface NoticeModalProps {
  title: string;
  isOpen: boolean;
  onOpenChange: DialogProps['onOpenChange'];
  buttonType: 'success' | 'error';
  buttonText: string;
  buttonDisabled?: boolean;
  handleButtonClick: () => void;
  zIndex?: number;
}

export const NoticeModal = ({
  title,
  isOpen,
  onOpenChange,
  buttonType,
  buttonText,
  buttonDisabled,
  handleButtonClick,
  children,
  zIndex = 12,
}: PropsWithChildren<NoticeModalProps>) => {
  return (
    <Modal.Root open={isOpen} onOpenChange={onOpenChange}>
      <Modal.Content
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex: zIndex }}
      >
        <Modal.Header title={title} />
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button
            width="100%"
            disabled={Boolean(buttonDisabled)}
            variant={
              buttonType === 'success' ? 'skyblue_500_fill' : 'cerise_400_fill'
            }
            paddingY={16}
            onClick={handleButtonClick}
          >
            {buttonText}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
