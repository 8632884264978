import styled from '@emotion/styled';
import { theme } from '@pairy/styles';
import * as Switch from '@radix-ui/react-switch';

export const SwitchRoot = styled(Switch.Root)`
  position: relative;
  width: 22.5px;
  height: 13.5px;
  background-color: ${theme.colors.zinc_400};
  border-radius: 9999px;

  &[data-state='checked'] {
    background-color: ${theme.colors.skyblue_400};
  }
`;

export const SwitchThumb = styled(Switch.Thumb)`
  display: block;
  width: 7px;
  height: 7px;
  background-color: white;
  border-radius: 50%;
  transform: translateX(3px);
  transition: transform 0.15s ease-in-out;
  will-change: transform;

  &[data-state='checked'] {
    transform: translateX(12px);
  }
`;
