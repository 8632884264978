const breakpoint = {
  mobile: '320px',
  tablet: '768px',
  desktop: '1280px',
};

export const theme = {
  colors: {
    inherit: 'inherit',
    current: 'currentColor',
    transparent: 'transparent',
    black: '#4F5860',
    white: '#ffffff',
    zinc_50: '#fafafa',
    zinc_100: '#f4f4f5',
    zinc_200: '#e4e4e7',
    zinc_300: '#d4d4d8',
    zinc_400: '#a1a1aa',
    zinc_500: '#71717a',
    zinc_600: '#52525b',
    zinc_700: '#3f3f46',
    zinc_800: '#27272a',
    zinc_900: '#18181b',
    skyblue_50: '#F2F7FC',
    skyblue_100: '#DAE9F9',
    skyblue_200: '#C8DDF4',
    skyblue_300: '#ACD0F5',
    skyblue_400: '#90BBE8',
    skyblue_500: '#769CC3',
    skyblue_600: '#547490',
    skyblue_700: '#42586D',
    skyblue_800: '#263848',
    skyblue_900: '#142432',
    viridian_50: '#EAF6F1',
    viridian_100: '#D8F4E7',
    viridian_200: '#BBEAD4',
    viridian_300: '#91E0BA',
    viridian_400: '#6BC69A',
    viridian_500: '#68A387',
    viridian_600: '#498166',
    viridian_700: '#306248',
    viridian_800: '#214A35',
    viridian_900: '#143222',
    loyalblue_50: '#F2F1FA',
    loyalblue_100: '#DEDDF3',
    loyalblue_200: '#C5C2ED',
    loyalblue_300: '#9A95E4',
    loyalblue_400: '#7873CB',
    loyalblue_500: '#59559E',
    loyalblue_600: '#383383',
    loyalblue_700: '#2A2572',
    loyalblue_800: '#211D60',
    loyalblue_900: '#14123A',
    cerise_50: '#FAF2F5',
    cerise_100: '#F1D5DF',
    cerise_200: '#ECB3C7',
    cerise_300: '#EA96B3',
    cerise_400: '#DB7095',
    cerise_500: '#AF5877',
    cerise_600: '#832F4D',
    cerise_700: '#66213A',
    cerise_800: '#54172D',
    cerise_900: '#3C0C1D',
    vermillion_50: '#FFEFEF',
    vermillion_100: '#FFD1D0',
    vermillion_200: '#FFB2B1',
    vermillion_300: '#FA9594',
    vermillion_400: '#EE7D7C',
    vermillion_500: '#D16664',
    vermillion_600: '#A63F3E',
    vermillion_700: '#89211F',
    vermillion_800: '#661A19',
    vermillion_900: '#480F0E',
    star: '#FFD541',
  },
  fontSize: {
    xs: '10px',
    sm: '12px',
    md: '14px',
    lg: '16px',
    xl: '20px',
    xxl: '24px',
    '2xl': '24px',
    xxxl: '32px',
    '3xl': '32px',
    xxxxl: '48px',
    '4xl': '48px',
  },
  lineHeight: {
    xs: '12px',
    sm: '14px',
    md: '17px',
    lg: '19px',
    xl: '24px',
    xxl: '29px',
    '2xl': '29px',
    xxxl: '38px',
    '3xl': '38px',
    xxxxl: '57px',
    '4xl': '57px',
  },
  fontWeight: {
    regular: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
  },
  textVariant: {
    'lg-bold': 'lg-bold',
    'lg-medium': 'lg-medium',
    'lg-regular': 'lg-regular',
    'lg-semibold': 'lg-semibold',
    'md-bold': 'md-bold',
    'md-medium': 'md-medium',
    'md-regular': 'md-regular',
    'md-semibold': 'md-semibold',
    'sm-bold': 'sm-bold',
    'sm-medium': 'sm-medium',
    'sm-regular': 'sm-regular',
    'sm-semibold': 'sm-semibold',
    'xl-bold': 'xl-bold',
    'xl-medium': 'xl-medium',
    'xl-regular': 'xl-regular',
    'xl-semibold': 'xl-semibold',
    'xs-bold': 'xs-bold',
    'xs-medium': 'xs-medium',
    'xs-regular': 'xs-regular',
    'xs-semibold': 'xs-semibold',
    '2xl-bold': '2xl-bold',
    '2xl-medium': '2xl-medium',
    '2xl-regular': '2xl-regular',
    '2xl-semibold': '2xl-semibold',
    '3xl-bold': '3xl-bold',
    '3xl-medium': '3xl-medium',
    '3xl-regular': '3xl-regular',
    '3xl-semibold': '3xl-semibold',
    '4xl-bold': '4xl-bold',
    '4xl-medium': '4xl-medium',
    '4xl-regular': '4xl-regular',
    '4xl-semibold': '4xl-semibold',
  },
  breakpoint,
  mediaQuery: {
    mobile: `@media only screen and (min-width: ${breakpoint.mobile})`,
    tablet: `@media only screen and (min-width: ${breakpoint.tablet})`,
    desktop: `@media only screen and (min-width: ${breakpoint.desktop})`,
  },
  elevation: {
    none: 'none',
    xs: '0px 4px 16px 0px rgba(79, 88, 96, 0.1)',
    sm: '0px 4px 16px 0px rgba(79, 88, 96, 0.15)',
    md: '0px 4px 16px 0px rgba(79, 88, 96, 0.20)',
  },
};
