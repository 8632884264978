import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from '@pairy/styles';

import { ButtonProps } from './index.types';

export const Button = styled.button<
  Required<Omit<ButtonProps, 'InnerWrappeGap' | 'innerStyle'>>
>`
  width: ${({ width }) => width};
  min-width: ${({ maxContentWidth }) =>
    maxContentWidth ? 'max-content' : 'auto'};
  padding: ${({ paddingX, paddingY }) => `${paddingY}px ${paddingX}px`};
  border-width: 1px;
  border-style: solid;

  margin: ${({ marginTop, marginRight, marginBottom, marginLeft }) =>
    `${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px`};
  background-color: ${({ backgroundColor }) => theme.colors[backgroundColor]};
  border-radius: ${({ radius }) => `${radius}px`};

  ${({ textVariant }) => `
    font-size: ${
      theme.fontSize[textVariant.split('-')[0] as keyof typeof theme.fontSize]
    };
    line-height: ${
      theme.lineHeight[
        textVariant.split('-')[0] as keyof typeof theme.lineHeight
      ]
    };
    font-weight: ${
      theme.fontWeight[
        textVariant.split('-')[1] as keyof typeof theme.fontWeight
      ]
    };
  `}

  ${({ variant }) => {
    switch (variant) {
      case 'primary': {
        return css`
          border-color: ${theme.colors.viridian_400};
          background-color: ${theme.colors.viridian_400};
          color: ${theme.colors.white};
          transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.viridian_500};
            background-color: ${theme.colors.viridian_500};
            color: ${theme.colors.white};
          }
        `;
      }

      case 'zinc_500': {
        return css`
          border-color: ${theme.colors.zinc_500};
          background-color: ${theme.colors.white};
          color: ${theme.colors.zinc_500};
          transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.zinc_700};
            background-color: ${theme.colors.white};
            color: ${theme.colors.zinc_700};
          }
        `;
      }
      case 'zinc_200': {
        return css`
          border-color: ${theme.colors.zinc_200};
          background-color: ${theme.colors.white};
          color: ${theme.colors.zinc_400};
          transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.zinc_100};
            background-color: ${theme.colors.zinc_100};
            color: ${theme.colors.black};
          }
        `;
      }
      case 'zinc_300': {
        return css`
          border-color: ${theme.colors.zinc_300};
          background-color: ${theme.colors.white};
          color: ${theme.colors.zinc_400};
          transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.zinc_400};
            background-color: ${theme.colors.white};
            color: ${theme.colors.zinc_700};
          }
        `;
      }
      case 'zinc_200_filled': {
        return css`
          border-color: ${theme.colors.zinc_200};
          background-color: ${theme.colors.zinc_200};
          color: ${theme.colors.zinc_500};
          transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.zinc_300};
            background-color: ${theme.colors.zinc_300};
            color: ${theme.colors.zinc_600};
          }
        `;
      }
      case 'zinc_200_reverse': {
        return css`
          border-color: ${theme.colors.transparent};
          background-color: ${theme.colors.transparent};
          color: ${theme.colors.black};
          opacity: 0.5;
          transition: all 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.zinc_200};
            background-color: ${theme.colors.zinc_200};
            color: ${theme.colors.black};
            opacity: 1;
          }
          &:active {
            border-color: ${theme.colors.zinc_200};
            background-color: ${theme.colors.white};
            color: ${theme.colors.black};
            opacity: 1;
          }
        `;
      }
      case 'zinc_700_fill': {
        return css`
          border-color: ${theme.colors.zinc_700};
          background-color: ${theme.colors.zinc_700};
          color: ${theme.colors.white};
          transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.zinc_900};
            background-color: ${theme.colors.zinc_900};
            color: ${theme.colors.white};
          }
        `;
      }
      case 'zinc_900_fill': {
        return css`
          border-color: ${theme.colors.zinc_900};
          background-color: ${theme.colors.zinc_900};
          color: ${theme.colors.white};
          transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.black};
            background-color: ${theme.colors.black};
            color: ${theme.colors.white};
          }
        `;
      }
      case 'cerise_50_fill': {
        return css`
          border-color: ${theme.colors.cerise_300};
          background-color: ${theme.colors.cerise_50};
          color: ${theme.colors.cerise_400};
          transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.cerise_500};
            background-color: ${theme.colors.cerise_100};
            color: ${theme.colors.cerise_500};
          }
        `;
      }
      case 'cerise_100': {
        return css`
          border-color: ${theme.colors.cerise_100};
          background-color: ${theme.colors.white};
          color: ${theme.colors.cerise_300};
          transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.cerise_100};
            background-color: ${theme.colors.cerise_100};
            color: ${theme.colors.cerise_400};
          }
        `;
      }
      case 'cerise_400': {
        return css`
          border-color: ${theme.colors.cerise_400};
          background-color: ${theme.colors.white};
          color: ${theme.colors.cerise_400};
          transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.cerise_500};
            background-color: ${theme.colors.white};
            color: ${theme.colors.cerise_500};
          }
        `;
      }
      case 'cerise_400_fill': {
        return css`
          border-color: ${theme.colors.cerise_400};
          background-color: ${theme.colors.cerise_400};
          color: ${theme.colors.cerise_50};
          transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.cerise_500};
            background-color: ${theme.colors.cerise_500};
            color: ${theme.colors.cerise_50};
          }
        `;
      }
      case 'black_fill': {
        return css`
          border-color: ${theme.colors.black};
          background-color: ${theme.colors.black};
          color: ${theme.colors.white};
          transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.zinc_700};
            background-color: ${theme.colors.zinc_700};
            color: ${theme.colors.white};
          }
        `;
      }
      case 'black_to_zinc_900': {
        return css`
          border-color: ${theme.colors.black};
          background-color: ${theme.colors.black};
          color: ${theme.colors.white};
          transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.zinc_900};
            background-color: ${theme.colors.zinc_900};
            color: ${theme.colors.white};
          }
        `;
      }
      case 'black': {
        return css`
          border-color: ${theme.colors.white};
          background-color: ${theme.colors.white};
          color: ${theme.colors.black};
          transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.white};
            background-color: ${theme.colors.white};
            color: ${theme.colors.black};
          }
        `;
      }
      case 'skyblue_100': {
        return css`
          border-color: ${theme.colors.skyblue_100};
          background-color: ${theme.colors.white};
          color: ${theme.colors.skyblue_400};
          transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.skyblue_50};
            background-color: ${theme.colors.skyblue_50};
            color: ${theme.colors.skyblue_500};
          }
        `;
      }
      case 'skyblue_100_fill': {
        return css`
          border-color: ${theme.colors.skyblue_100};
          background-color: ${theme.colors.skyblue_100};
          color: ${theme.colors.black};
          transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.skyblue_200};
            background-color: ${theme.colors.skyblue_200};
            color: ${theme.colors.black};
          }
        `;
      }
      case 'skyblue_600_fill': {
        return css`
          border-color: ${theme.colors.skyblue_600};
          background-color: ${theme.colors.skyblue_600};
          color: ${theme.colors.white};
          transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.skyblue_500};
            background-color: ${theme.colors.skyblue_500};
            color: ${theme.colors.white};
          }
        `;
      }

      case 'skyblue_500_fill': {
        return css`
          border-color: ${theme.colors.skyblue_500};
          background-color: ${theme.colors.skyblue_500};
          color: ${theme.colors.skyblue_100};
          transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.skyblue_700};
            background-color: ${theme.colors.skyblue_700};
            color: ${theme.colors.skyblue_100};
          }
        `;
      }
      case 'skyblue_400_fill': {
        return css`
          border-color: ${theme.colors.skyblue_400};
          background-color: ${theme.colors.skyblue_400};
          color: ${theme.colors.white};
          transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.skyblue_500};
            background-color: ${theme.colors.skyblue_500};
            color: ${theme.colors.white};
          }
        `;
      }
      case 'skyblue_200_fill': {
        return css`
          border-color: ${theme.colors.skyblue_200};
          background-color: ${theme.colors.skyblue_200};
          color: ${theme.colors.zinc_900};
          transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.skyblue_300};
            background-color: ${theme.colors.skyblue_300};
            color: ${theme.colors.zinc_900};
          }
        `;
      }
      case 'skyblue_300': {
        return css`
          border-color: ${theme.colors.skyblue_300};
          background-color: ${theme.colors.white};
          color: ${theme.colors.skyblue_300};
          transition: all 0.15s ease-in-out;

          &:hover {
            border-color: ${theme.colors.skyblue_500};
            background-color: ${theme.colors.skyblue_50};
            color: ${theme.colors.skyblue_500};
          }
        `;
      }
      case 'skyblue_300_fill': {
        return css`
          border-color: ${theme.colors.skyblue_300};
          background-color: ${theme.colors.skyblue_300};
          color: ${theme.colors.white};
          transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.skyblue_400};
            background-color: ${theme.colors.skyblue_400};
            color: ${theme.colors.white};
          }
        `;
      }
      case 'loyalblue_300_fill': {
        return css`
          border-color: ${theme.colors.loyalblue_300};
          background-color: ${theme.colors.loyalblue_300};
          color: ${theme.colors.white};
          transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.loyalblue_400};
            background-color: ${theme.colors.loyalblue_400};
            color: ${theme.colors.white};
          }
        `;
      }
      case 'skyblue_400': {
        return css`
          border-color: ${theme.colors.skyblue_400};
          background-color: ${theme.colors.white};
          color: ${theme.colors.skyblue_400};
          transition: all 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.skyblue_500};
            color: ${theme.colors.skyblue_600};
          }
        `;
      }
      case 'skyblue_500': {
        return css`
          border-color: ${theme.colors.skyblue_500};
          background-color: ${theme.colors.white};
          color: ${theme.colors.skyblue_500};
          transition: all 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.skyblue_600};
            color: ${theme.colors.skyblue_600};
          }
        `;
      }
      case 'vermillion_400_fill': {
        return css`
          border-color: ${theme.colors.vermillion_400};
          background-color: ${theme.colors.vermillion_400};
          color: ${theme.colors.white};
          transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.vermillion_500};
            background-color: ${theme.colors.vermillion_500};
            color: ${theme.colors.white};
          }
        `;
      }
      case 'viridian_300': {
        return css`
          border-color: ${theme.colors.viridian_300};
          background-color: ${theme.colors.white};
          color: ${theme.colors.viridian_400};
          transition: background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.viridian_400};
            background-color: ${theme.colors.zinc_100};
            color: ${theme.colors.viridian_500};
          }
        `;
      }
      default: {
        return css`
          border-color: ${theme.colors.transparent};
          background-color: ${theme.colors.transparent};
          color: ${theme.colors.zinc_500};
          transition: border-color 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            border-color: ${theme.colors.transparent};
            color: ${theme.colors.black};
          }
        `;
      }
    }
  }}

  &:disabled {
    border-color: ${theme.colors.zinc_200};
    background-color: ${theme.colors.zinc_200};
    color: ${theme.colors.zinc_400};
    cursor: not-allowed;
  }

  ${({ borderLess }) =>
    borderLess &&
    css`
      border: none;
    `}
`;
