import { useEffect, useState } from 'react';
import { CtaForm } from '@pairy/sheard/types';

import useGetPrevCtaForm from '@/hooks/queries/file/useGetPrevCtaForm';

interface Props {
  fileId: number;
}

const usePrevCtaFormData = ({ fileId }: Props) => {
  const [formData, setFormData] = useState<CtaForm | null>(null);

  const { data } = useGetPrevCtaForm(
    { fileId },
    {
      enabled: !!fileId,
      select: (data) => data.data.data,
    },
  );

  const handleChangeFormDataWithKey = <T extends keyof CtaForm>(
    key: T,
    value: CtaForm[T],
  ) => {
    setFormData(
      (prev) =>
        ({
          ...prev,
          [key]: value,
        } as CtaForm),
    );
  };

  useEffect(() => {
    if (data) {
      setFormData(data.form);
    }
  }, [data]);

  return {
    hash: data?.hash,
    formData,
    isFormEditable: data?.isFormEditable,
    handleChangeFormDataWithKey,
  };
};

export default usePrevCtaFormData;
