import { useRouter } from 'next/router';

import useCheckFileUpload from '@/hooks/mutations/file/useCheckFileUpload';

interface Props {
  refetch: () => void;
}

const useHandleFileUploadDone = ({ refetch }: Props) => {
  const router = useRouter();
  const { mutate } = useCheckFileUpload({
    onSuccess: () => {
      refetch();
    },
  });

  const handleCloseButtonClick = (fileId: number) => {
    mutate({ fileId });
  };

  const handleSuccessFileCheckClick = (fileId: number) => {
    handleCloseButtonClick(fileId);
    router.push(`/user/pdf/tracking-clients?fileId=${fileId}`);
  };

  return {
    handleCloseButtonClick,
    handleSuccessFileCheckClick,
  };
};

export default useHandleFileUploadDone;
