import styled from '@emotion/styled';
import { theme } from '@pairy/styles';
import React from 'react';

export const Wrapper = styled.div<{
  padding: string;
  borderColor: keyof typeof theme.colors;
  backgroundColor: keyof typeof theme.colors;
  width: string;
}>`
  position: relative;
  display: flex;
  width: ${({ width }) => width};
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ padding }) => padding};
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;

  border: 1px solid ${({ borderColor }) => theme.colors[borderColor]};

  background-color: ${({ backgroundColor }) => theme.colors[backgroundColor]};
  border-radius: 8px;
  gap: 16px;
`;

export const TitleWrapper = styled.div<{
  padding: string;
  isTitleImage: boolean;
}>`
  position: relative;
  display: flex;
  overflow: hidden;

  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  padding: ${({ padding }) => padding};
  border-bottom: 1px solid ${theme.colors.zinc_200};

  background: ${theme.colors.white};

  border-radius: 8px 8px 0 0;

  ${({ isTitleImage }) => isTitleImage && 'height: 100px;'}
`;

export const ContentWrapper = styled.div<{
  padding: string;
  hasTitle: boolean;
}>`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  padding: ${({ padding }) => padding};
  padding-bottom: 0;
  ${({ hasTitle }) => hasTitle && 'padding-top: 0;'}
  gap: 16px;
`;

export const ContentInnerWrapper = styled.div<{
  overflow: React.CSSProperties['overflow'];
}>`
  overflow: ${({ overflow }) => overflow};
  width: 100%;
  flex: 1;
`;

export const BottomCTAButtonWrapper = styled.div<{
  isMultipleCTAButtons: boolean;
}>`
  ${({ isMultipleCTAButtons }) =>
    isMultipleCTAButtons && 'display: flex; gap: 16px;'}

  width: 100%;
  padding: 0 16px;
`;
