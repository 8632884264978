import { PropsWithChildren } from 'react';

import Button from '../../Button';
import { Modal } from '../Modal';

export interface Props {
  title: string;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  closeButtonText?: string;
  confirmButtonText?: string;
  handleModalCloseAction?: () => void;
}

export const AlertModal = ({
  title,
  isOpen,
  setIsOpen,
  children,
  closeButtonText,
  confirmButtonText,
  handleModalCloseAction,
}: PropsWithChildren<Props>) => {
  return (
    <Modal.Root
      open={isOpen}
      onOpenChange={(open: boolean) => {
        setIsOpen(open);
        if (!open && handleModalCloseAction) {
          handleModalCloseAction();
        }
      }}
    >
      <Modal.Content>
        <Modal.Header title={title} buttonText={closeButtonText} />
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Modal.Close>
            <Button
              variant="vermillion_400_fill"
              textVariant="lg-regular"
              paddingX={24}
            >
              {confirmButtonText ?? '확인'}
            </Button>
          </Modal.Close>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};

export default AlertModal;
