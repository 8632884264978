import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { Dispatch, SetStateAction } from 'react';
import { Text, Icon } from '@pairy/pds-react';

import * as Styled from './index.styles';

interface Props<T> {
  items: readonly { label: string; value: T }[] | null;
  onValueChanges: (
    value: T,
    setValue?: Dispatch<SetStateAction<string>>,
  ) => void | T;
  initialValue?: T;
}

const SettingDropdown = <T extends string>({
  items,
  onValueChanges,
  initialValue,
}: Props<T>) => {
  if (items === null) {
    return null;
  }

  const [value, setValue] = React.useState<string>(
    items[items.findIndex((item) => item.label === initialValue)]?.label ??
      initialValue,
  );

  const handleChangeValue = (value: string) => {
    const index = items.findIndex((item) => item.value === value);

    setValue(items[index].label);

    onValueChanges(value as T, setValue);
  };

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <Styled.TriggerButton>
          <Text variant="md-medium" color="zinc_400">
            {value}
          </Text>
          <Icon icon="ph:caret-down" size={17} color="zinc_400" />
        </Styled.TriggerButton>
      </DropdownMenu.Trigger>

      <Styled.DropdownMenuContent>
        <DropdownMenu.RadioGroup
          value={value}
          onValueChange={handleChangeValue}
        >
          {items.map((item) => (
            <Styled.DropdownMenuRadioItem
              key={item.value}
              value={item.value}
              className={item.label === value ? 'active' : ''}
            >
              <Text variant="md-medium" color="zinc_400">
                {item.label}
              </Text>
            </Styled.DropdownMenuRadioItem>
          ))}
        </DropdownMenu.RadioGroup>
      </Styled.DropdownMenuContent>
    </DropdownMenu.Root>
  );
};

export default SettingDropdown;
