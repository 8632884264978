import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import { Icon } from '../Icon';

const shakeAnimation = keyframes`
  0% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(-5px);
  }
`;

export const Wrapper = styled.div<{ bottom: number; size: number }>`
  position: fixed;
  bottom: ${({ bottom }) => bottom}px;
  left: calc(50% - ${({ size }) => size / 2}px);
  width: 310px;
  height: 100px;
  background: linear-gradient(180deg, rgb(255 255 255 / 0%) 0%, #fff 49.5%);
  transform: translateX(calc(-50% + ${({ size }) => size / 2}px));
`;

export const AnimationIcon = styled(Icon)`
  position: fixed;
  bottom: 16px;
  left: calc(50% - 12px);
  animation: ${shakeAnimation} 1s infinite;
`;
