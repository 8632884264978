import { atom } from 'jotai';
import { ReactNode } from 'react';

export const toastsAtom = atom<
  {
    id: string;
    duration: number;
    message: ReactNode;
  }[]
>([]);
