import { ErrorMessage } from '@hookform/error-message';
import { DeepMap, FieldError, FieldValues } from 'react-hook-form';

import Text from '../../Text';

import { Props } from './index.types';

export const HookFormErrorMessage = <
  TFieldErrors extends DeepMap<FieldValues, FieldError>,
>({
  errors,
  name,
}: Props<TFieldErrors>) => {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => (
        <Text
          as="p"
          color="cerise_300"
          variant="sm-regular"
          marginTop={8}
          role="alert"
        >
          {message}
        </Text>
      )}
    />
  );
};

export default HookFormErrorMessage;
