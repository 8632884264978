import {
  useQuery,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { CtaForm } from '@pairy/sheard/types';

import { api } from '@/utils/api';
import { ApiErrorResponse, ApiResponse } from '@/types/api';

export interface GetPrevCtaFormRequest {
  fileId: number;
}

export interface GetPrevCtaFormResponse {
  hash: string;
  form: CtaForm;
  isFormEditable: boolean;
}

export const getPrevCtaForm = ({ fileId }: GetPrevCtaFormRequest) => {
  return api.get<ApiResponse<GetPrevCtaFormResponse>>(`/v2/file/form/edited`, {
    params: { fileId },
  });
};

const useGetPrevCtaForm = <
  TData = AxiosResponse<ApiResponse<GetPrevCtaFormResponse>>,
  TError = AxiosError<ApiErrorResponse>,
>(
  request: GetPrevCtaFormRequest,
  options?: UseQueryOptions<
    AxiosResponse<ApiResponse<GetPrevCtaFormResponse>>,
    TError,
    TData,
    ['getPrevCtaForm', GetPrevCtaFormRequest]
  >,
): UseQueryResult<TData, TError> => {
  return useQuery(['getPrevCtaForm', request], () => getPrevCtaForm(request), {
    ...options,
  });
};

export default useGetPrevCtaForm;
