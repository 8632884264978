import { Text } from '@pairy/pds-react';

import { FileUploadStatusType } from '@/hooks/queries/file/useFileUploadList';

interface Props {
  totalPage: number;
  uploadStatus: FileUploadStatusType;
}
const PageStatus = ({ totalPage, uploadStatus }: Props) => {
  const getCurrentUploadingPage = () => {
    if (uploadStatus.includes('Convert')) {
      const page = uploadStatus.replace('Convert:', '');
      return Number(page);
    }
    return 0;
  };

  return (
    <Text variant="lg-semibold" color="zinc_400">
      {getCurrentUploadingPage()} / {totalPage} 완료
    </Text>
  );
};

export default PageStatus;
