import styled from '@emotion/styled';
import { theme } from '@pairy/styles';

import { DotProps } from './index.types';

export const Wrapper = styled.span<Required<DotProps>>`
  display: inline-block;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  margin: ${({ marginTop, marginRight, marginBottom, marginLeft }) => {
    return `${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px`;
  }};

  background: ${({ color }) => theme.colors[color]};
  border-radius: 5px;
`;
