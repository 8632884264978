import React from 'react';

import * as Styled from './index.styles';

export interface ModalBodyProps {
  padding?: number;
}

export const Body = ({
  padding = 16,
  children,
}: React.PropsWithChildren<ModalBodyProps>) => {
  return <Styled.Wrapper padding={padding}>{children}</Styled.Wrapper>;
};

export default Body;
