import {
  useMutation,
  type QueryKey,
  type UseMutationOptions,
  type UseMutationResult,
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { CtaForm } from '@pairy/sheard/types';

import { api } from '@/utils/api';
import { ApiErrorResponse, ApiResponse } from '@/types/api';

export interface PostCtaFormRequest {
  hash: string;
  form: CtaForm;
}

export const postCtaForm = ({ hash, form }: PostCtaFormRequest) => {
  return api.post<ApiResponse>('/v2/file/form', {
    hash,
    form,
  });
};

const usePostCtaForm = <TError = AxiosError<ApiErrorResponse>>(
  options?: UseMutationOptions<
    AxiosResponse<ApiResponse>,
    TError,
    PostCtaFormRequest,
    QueryKey
  >,
): UseMutationResult<
  AxiosResponse<ApiResponse>,
  TError,
  PostCtaFormRequest,
  QueryKey
> => {
  return useMutation(postCtaForm, {
    ...options,
  });
};

export default usePostCtaForm;
