import { atom } from 'jotai';
import { UserFileInfoType } from '@/../../packages/next-pdf-viewer/src/types/file';

import { Nullable } from '@/types/helper';
import { ThumbnailType } from '@/types/file';

export type CtaSettingType = null | 'cta-button' | 'cta-form' | 'message-box';
/* setting viewer에서 현재 페이지 번호를 담고 있는 상태 */
export const currentPageAtom = atom<number>(1);

/* 브라우저 크기에 따라서 뷰어에서 이미지의 크기를 맞춰서 보여주기 위해서 현재 브라우저의 width,height를 저장한다 */
export const pageWrapperSizeAtom = atom<{ width: number; height: number }>({
  width: 0,
  height: 0,
});

/* page navigation drawer가 열린 상태에 따라서 뷰어의 나머지 영역(=이미지, setting drawer 등등)의 크기를 조절하기 위해 가지고 있는 상태 */
export const isOpenDrawerAtom = atom<boolean>(false);

export const ctaSettingTypeAtom = atom<CtaSettingType>(null);

export const ctaFormHashAtom = atom<Nullable<string>>(null);

export const companyNameAtom = atom<string>('');

export const isFirstLoadedAtom = atom<boolean>(false);

/*user의 Plan에 따라서 생성을 제한하기 위해 유저 플랜을 저장하는 전역 상태 */
export const userPlanAtom = atom<UserFileInfoType['plan']>('Free');

/* Thumbnail을 저장하고 렌더링하는 상태값 */
export const thumbnailsAtom = atom<ThumbnailType[] | null>(null);
