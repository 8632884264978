import { Icon as Iconify } from '@iconify/react';
import { theme } from '@pairy/styles';
import { useMemo } from 'react';

import { IconProps, IconNames } from './index.types';

export const Icon = ({ size, icon, color = 'inherit', ...rest }: IconProps) => {
  const width = useMemo(
    () => (typeof size === 'number' ? size : size.width),
    [size],
  );
  const height = useMemo(
    () => (typeof size === 'number' ? size : size.height),
    [size],
  );

  if (color === 'default') {
    return <Iconify icon={icon} width={width} height={height} {...rest} />;
  }

  return (
    <Iconify
      icon={icon}
      width={width}
      height={height}
      color={theme.colors[color]}
      {...rest}
    />
  );
};

export type { IconNames, IconProps };
