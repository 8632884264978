export const Z_INDEX = {
  onTop: 1,
  justAboveOnTop: 2,
  loading: 3,
  layoutHeader: 10,
  dropdownMenuContents: 10,
  fileUploadElement: 12,
  loadingWrapper: 100,
  planModalDimmer: 104,
  planModalContents: 105,
  planModalContainer: 106,
  rootModal: 151,
  /* messageBox z-index */
  rootLayer: 1,
  messageBoxLayer: 2,
  blockLayer: 4,
  buttonLayer: 4,
  messageBox: 9999,
};
