import styled from '@emotion/styled';
import { Text } from '@pairy/pds-react';
import { motion } from 'framer-motion';

import { Z_INDEX } from '@/constants/zIndex';

export const Container = styled(motion.div)`
  position: fixed;

  z-index: ${Z_INDEX.fileUploadElement};
  bottom: 0;
  display: flex;

  width: 100%;
  max-height: 100vh;
  flex-direction: column;
  align-items: center;

  justify-content: center;
  padding: 32px 180px;
  gap: 20px;
  overflow-y: scroll;
`;

export const Wrapper = styled(motion.div)`
  position: relative;
  width: 100%;
  max-width: 1080px;

  padding: 32px;
  background-color: ${({ theme }) => theme.colors.white};

  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.elevation.md};
`;

export const TextWrapper = styled(Text)`
  overflow-wrap: break-word;
  word-wrap: break-word;
`;

export const BlueButton = styled.button`
  padding: 12px 16px;
  background-color: ${({ theme }) => theme.colors.skyblue_500};
  border-radius: 4px;

  transition: all 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;
