import type { AppProps } from 'next/app';
import { Global, ThemeProvider } from '@emotion/react';
import { useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { theme, globalStyle } from '@pairy/styles';
import { ToastContainer } from '@pairy/pds-react';

import Scripts from '@/components/Scripts';
import usePageView from '@/hooks/usePageView';
import Head from '@/components/Head';
import FileUploadingElements from '@/components/Layouts/FileUploadingElements';

dayjs.extend(utc);

function MyApp({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(() => new QueryClient());
  usePageView();

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Head />
        <Scripts />
        <ToastContainer />
        <Component {...pageProps} />
        <FileUploadingElements />
        <Global styles={globalStyle} />
        <ReactQueryDevtools initialIsOpen={false} />
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default MyApp;
