import { keyframes } from '@emotion/react';

const pulse = (rgb = '0, 0, 0', alpha = 0.5) => keyframes`
  0% {
    box-shadow: none;
  }

  50% {
    box-shadow: 0 0 0 0 rgba(${rgb}, ${alpha}); 
  }

  85% {
    box-shadow: 0 0 0 10px rgba(${rgb}, 0); 
  }

  100% {
    box-shadow: 0 0 0 40px rgba(${rgb}, 0);
  }
`;

export const animation = {
  pulse,
};
