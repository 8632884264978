import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { Z_INDEX } from '@/constants/zIndex';

const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const TriggerButton = styled.button`
  display: flex;
  width: 256px;

  height: 33px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 8px 8px 8px 16px;
  border: 1px solid ${({ theme }) => theme.colors.zinc_200};

  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  gap: 8px;
`;

export const DropdownMenuContent = styled(DropdownMenu.Content)`
  position: relative;
  z-index: ${Z_INDEX.dropdownMenuContents};
  top: 4px;
  display: flex;
  width: 256px;
  max-height: 288px;

  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.colors.zinc_200};

  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;

  box-shadow: ${({ theme }) => theme.elevation.xs};
  gap: 4px;
  overflow-y: scroll;

  &[data-side='bottom'] {
    animation: ${slideUp} 0.15s ease-in-out;
  }
`;

export const DropdownMenuRadioItem = styled(DropdownMenu.RadioItem)`
  width: 256px;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.5;

  transition: all 0.15s ease-in-out;
  &[data-highlighted] {
    background: ${({ theme }) => theme.colors.zinc_100};
    opacity: 1;
    outline: none;
  }

  &.active {
    opacity: 1;
  }
`;
