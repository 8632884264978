import React, { Ref, forwardRef } from 'react';

import { Props } from './index.types';
import * as Styled from './index.styles';

export const Stack = forwardRef(
  (
    { children, gap = 0, ...rest }: React.PropsWithChildren<Props>,
    ref: Ref<HTMLElement> | undefined,
  ) => {
    return (
      <Styled.FlexWrapper gap={gap} {...rest} ref={ref}>
        {children}
      </Styled.FlexWrapper>
    );
  },
);

Stack.displayName = 'Stack';

export default Stack;
