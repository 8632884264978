import axios from 'axios';

import { API, SALESCLUE_HOST } from '@/constants/api';

const reverseProxy = (host: string) => `https://${host}/api`;

export const getBaseUrl = (host: string) => {
  if (process.env.NODE_ENV === 'production' && host === SALESCLUE_HOST.APP) {
    return API.PRODUCTION_API;
  }

  if (
    process.env.NODE_ENV === 'production' &&
    host === SALESCLUE_HOST.RELEASE
  ) {
    return API.PRODUCTION_API;
  }

  if (process.env.NODE_ENV === 'production' && host === SALESCLUE_HOST.DEV) {
    return API.DEVELOPMENT_API;
  }

  return reverseProxy(host);
};

export const api = axios.create({
  baseURL: getBaseUrl(globalThis?.window?.location.host),
  withCredentials: true,
});
