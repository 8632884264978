import styled from '@emotion/styled';
import { theme } from '@pairy/styles';

import Flex from '../../../Flex';

export const FlexHeader = styled(Flex)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  padding: 16px;

  border-bottom: 1px solid ${theme.colors.zinc_200};
`;
