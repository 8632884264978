export const API = {
  PRODUCTION_API: 'https://api.salesclue.io',
  DEVELOPMENT_API: 'https://api_dev.salesclue.io',
} as const;

export const SALESCLUE_HOST = {
  DEV: 'dev.salesclue.io',
  RELEASE: 'alpha.salesclue.io',
  APP: 'app.salesclue.io',
} as const;
