import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import * as Toast from '@radix-ui/react-toast';

const hide = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const show = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ToastRoot = styled(Toast.Root)`
  max-width: 1080px;

  &[data-state='open'] {
    animation: ${show} 0.15s ease-in-out;
  }

  &[data-state='closed'] {
    animation: ${hide} 0.15s ease-in-out;
  }
`;

export const ToastViewport = styled(Toast.Viewport)`
  position: fixed;
  z-index: 9999;
  top: 24px;
  left: 50%;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  gap: 16px;
  list-style: none;
  transform: translateX(-50%);
`;

export const ToastDescriptionWrapper = styled.div`
  display: flex;

  width: 100%;
  flex-direction: column;
  align-items: center;
`;
