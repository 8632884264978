import { Text } from '@pairy/pds-react';
import styled from '@emotion/styled';

export const WrapText = styled(Text)`
  white-space: pre-line;
`;

export const DivideText = styled(Text)`
  white-space: pre-wrap;
`;
