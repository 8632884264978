import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from '@pairy/styles';

import { SkeletonProps } from './index.types';

const pulse = keyframes`
  0% {
    background-color: ${theme.colors.zinc_200};
  }
  50% {
    background-color: ${theme.colors.zinc_300};
  }
  100% {
    background-color: ${theme.colors.zinc_200};
  }
`;

export const Wrapper = styled.div<Required<SkeletonProps>>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  padding: ${({ paddingTop, paddingRight, paddingBottom, paddingLeft }) =>
    `${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px`};
  margin: ${({ marginTop, marginRight, marginBottom, marginLeft }) =>
    `${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px`};

  animation: ${pulse} 3s ease-in-out infinite;

  border-radius: ${({ variants }) => {
    switch (variants) {
      case 'circular':
        return '50%';
      case 'text':
        return '4px';
      case 'rectangular-rounded':
        return '8px';
      default:
        return '0';
    }
  }};
`;
