import { Flex, Stack, Text } from '@pairy/pds-react';
import { PropsWithChildren, ReactNode } from 'react';

import * as Styled from './index.styles';

interface Props {
  title: string;
  size?: 'sm' | 'md';
  description?: string;
  rightElement?: ReactNode;
  notice?: string;
}

const Section = ({
  title,
  children,
  description,
  size = 'md',
  rightElement,
  notice,
}: PropsWithChildren<Props>) => {
  return (
    <Stack
      gap={size === 'md' ? 16 : 8}
      direction="column"
      alignItems="flex-start"
    >
      <Flex justifyContent="space-between">
        <Stack direction="column" gap={4}>
          <Text
            variant={size === 'md' ? 'md-medium' : 'sm-medium'}
            color={size === 'md' ? 'black' : 'zinc_400'}
          >
            {title}
          </Text>
          {description && (
            <Styled.WrapText variant="sm-medium" color="zinc_400">
              {description}
            </Styled.WrapText>
          )}
          {notice && (
            <Styled.DivideText variant="sm-medium" color="cerise_500">
              {notice}
            </Styled.DivideText>
          )}
        </Stack>
        {rightElement}
      </Flex>
      {children}
    </Stack>
  );
};

export default Section;
