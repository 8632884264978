import { forwardRef, PropsWithChildren, Ref } from 'react';

import { Icon } from '../Icon';

import * as Styled from './index.styles';
import { Props } from './index.types';

export const Badge = forwardRef(function Badge(
  {
    color = 'inherit',
    backgroundColor = 'inherit',
    borderColor = 'inherit',
    marginTop = 0,
    marginRight = 0,
    marginBottom = 0,
    marginLeft = 0,
    paddingX = 9,
    paddingY = 3,
    borderStyle = 'solid',
    radius = 9999,
    icon,
    iconSize = 14,
    iconColor = 'inherit',
    children,
    ...rest
  }: PropsWithChildren<Partial<Props>>,
  forwardedRef: Ref<HTMLDivElement>,
) {
  return (
    <Styled.Wrapper
      color={color}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      marginTop={marginTop}
      marginRight={marginRight}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      paddingX={paddingX}
      paddingY={paddingY}
      borderStyle={borderStyle}
      ref={forwardedRef}
      radius={radius}
      {...rest}
    >
      {icon && <Icon icon={icon} size={iconSize} color={iconColor} />}
      {children}
    </Styled.Wrapper>
  );
});

export default Badge;
