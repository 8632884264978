import styled from '@emotion/styled';
import { theme } from '@pairy/styles';

import { FlexItemProps } from './index.types';

export const FlexItem = styled.div<Required<FlexItemProps>>`
  flex-basis: ${({ flexBasis }) => flexBasis};
  flex-grow: ${({ flexGrow }) => flexGrow};
  flex-shrink: ${({ flexShrink }) => flexShrink};
  background-color: ${({ backgroundColor }) => theme.colors[backgroundColor]};
`;
