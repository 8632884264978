import { DialogProps } from '@radix-ui/react-dialog';
import React, { PropsWithChildren } from 'react';

import Stack from '../../Stack';
import Button from '../../Button';
import { ButtonType } from '../../Button/index.types';
import { Modal } from '../Modal';

export interface ConfirmModalProps {
  title: string;
  isOpen: boolean;
  onOpenChange: DialogProps['onOpenChange'];
  approveButton: FooterButtonType;
  rejectButton: FooterButtonType;
}

export interface FooterButtonType {
  text: string;
  variant: ButtonType;
  isDisabled?: boolean;
  onClick: (e?: React.MouseEvent) => void;
}

export const ConfirmModal = ({
  title,
  isOpen,
  onOpenChange,
  approveButton,
  rejectButton,
  children,
}: PropsWithChildren<ConfirmModalProps>) => {
  return (
    <Modal.Root open={isOpen} onOpenChange={onOpenChange}>
      <Modal.Content
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <Modal.Header title={title} />
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Stack gap={16}>
            <Button
              type="button"
              width="50%"
              paddingY={16}
              disabled={Boolean(rejectButton.isDisabled)}
              variant={rejectButton.variant}
              onClick={rejectButton.onClick}
            >
              {rejectButton.text}
            </Button>
            <Button
              type="button"
              width="50%"
              paddingY={16}
              disabled={Boolean(approveButton.isDisabled)}
              variant={approveButton.variant}
              onClick={approveButton.onClick}
            >
              {approveButton.text}
            </Button>
          </Stack>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
