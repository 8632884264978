import Script from 'next/script';

const Recatch = () => {
  if (!process.env.NEXT_PUBLIC_RECATCH_KEY) {
    return null;
  }

  return (
    <Script id="nextjs-recatch">
      {`
        (function (r, e, c, a, t, ch) {
          var h=r.getElementsByTagName(e)[0],i=r.createElement(c);
          i.async=true;
          i.id='recatch-embed-script';
          i.src='https://cdn.recatch.cc/recatch-embed.iife.js?t='+a[0]+'&b='+a[1]+'&c='+t+'&th='+ch;
          h.appendChild(i);
        })(document,'head','script',['salesclue','${process.env.NEXT_PUBLIC_RECATCH_KEY}'],'inbound-form','light')
      `}
    </Script>
  );
};

export default Recatch;
