import React, { isValidElement } from 'react';

import Text from '../Text';
import Stack from '../Stack';

import { Props, variants } from './index.types';
import * as Styled from './index.styles';

export const Tag = React.forwardRef<HTMLDivElement, Props>(function Tag(
  {
    as = 'div',
    variant,
    textVariant = 'md-medium',
    children,
    leftIcon,
    gap = 4,
    marginTop = 0,
    marginBottom = 0,
    marginLeft = 0,
    marginRight = 0,
    borderColor = 'inherit',
    borderWidth = '0',
    ...rest
  },
  forwardedRef,
) {
  return (
    <Styled.Wrapper
      as={as}
      variant={variant}
      ref={forwardedRef}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      borderColor={borderColor}
      borderWidth={borderWidth}
      {...rest}
    >
      <Stack gap={gap} alignItems="center">
        {isValidElement(leftIcon) && leftIcon}
        <Text variant={textVariant} color={variants[variant].fontColor}>
          {children}
        </Text>
      </Stack>
    </Styled.Wrapper>
  );
});

export default Tag;
