import { theme } from '@pairy/styles';
import React from 'react';

import * as Styled from './index.styles';

export const Footer = ({
  children,
  ...rest
}: React.PropsWithChildren<{
  backgroundColor?: keyof typeof theme.colors;
}>) => {
  return (
    <Styled.FlexWrapper justifyContent="center" {...rest}>
      {children}
    </Styled.FlexWrapper>
  );
};

export default Footer;
