import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';

import {
  UploadingFileInfoListAtom,
  createSuccessFileIdListAtom,
} from '@/state/file/uploadingfile.atom';

interface Props {
  handleCloseButtonClick: (fileId: number) => void;
}

const useHandleCreateSuccessModalClose = ({
  handleCloseButtonClick,
}: Props) => {
  const [uploadingFileList, setUploadingFileList] = useAtom(
    UploadingFileInfoListAtom,
  );
  const [createSuccessFileIdList, setCreateSuccessFileIdList] = useAtom(
    createSuccessFileIdListAtom,
  );
  const [isActiveTab, setIsActiveTab] = useState(false);

  useEffect(() => {
    const handleWindowFocus = () => {
      setIsActiveTab(true);
    };

    const handleWindowBlur = () => {
      setIsActiveTab(false);
    };

    window.addEventListener('focus', handleWindowFocus);
    window.addEventListener('blur', handleWindowBlur);

    return () => {
      window.removeEventListener('focus', handleWindowFocus);
      window.removeEventListener('blur', handleWindowBlur);
    };
  }, []);

  const handleTimerStart = (id: number) => {
    setTimeout(() => {
      if (isActiveTab) {
        setUploadingFileList(
          uploadingFileList.filter((item) => item.id !== id),
        );
        setCreateSuccessFileIdList(
          createSuccessFileIdList.filter((item) => item !== id),
        );
        handleCloseButtonClick(id);
      }
    }, 5000);
  };

  useEffect(() => {
    if (isActiveTab) {
      createSuccessFileIdList.forEach((id) => handleTimerStart(id));
    }
  }, [isActiveTab, createSuccessFileIdList]);
};

export default useHandleCreateSuccessModalClose;
